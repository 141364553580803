import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Edit, Trash2 } from "react-feather";

import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { http, httpfile } from "../../config/axiosConfig";

const AddBanner = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate();

//   const [nameError, setNameError] = useState("");
  const [imageError, setImageError] = useState("");
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (e.target.files) {
      setData({ ...data, [name]: e.target.files[0] });
    } else {
      setData({ ...data, [name]: value });
    }
    // if (nameError && value.trim() !== "") {
    //   setNameError("");
    // }
    if ( (name === "image" && imageError)) {
      clearError(name);
    }
  };
  const validateInput = () => {
    let valid = true;
    // if (!data.name || data.name.trim() === "") {
    //   setNameError("Name is required");
    //   valid = false;
    // } else {
    //   setNameError("");
    // }
    if (!data.image || (typeof data.image === "object" && !data.image.name)) {
      setImageError("Image is required");
      valid = false;
    } else {
      setImageError("");
    }

    return valid;
  };
  const clearError = (name) => {
    if  (name === "image") {
      setImageError("");
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateInput()) {
      await httpfile
        .post(`BannerCreate`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data) {
            navigate("/Banner");
            toast.success(" Add Banner");
          }
        })
        .catch((err) => {
          console.log(err, "========== from .BannerCreate");
        });
    }
  };
  return (
    <div className="app-content content ">
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow"></div>
      <div className="content-wrapper container-xxl p-0">
        <div className="card-header card">
          <h4 className="mb-0 card-title"> Add Banner</h4>
        </div>
        <div className="card">
          <form
            className="add-new-user modal-content pt-0"
            noValidate="novalidate"
            onSubmit={handleSubmit}
          >
            <div className="modal-body flex-grow-1">
              {/* <div className="mb-1">
                <label htmlFor="">Name</label>
                <input
                  type="text"
                  className={`form-control ${nameError && "is-invalid"}`}
                  id="basic-icon-default-fullname"
                  placeholder="Enter Name"
                  onChange={(e) => handleInputChange(e)}
                  name="name"
                  aria-label="John Doe"
                  tabIndex={1}
                  aria-describedby="basic-icon-default-fullname2"
                />
                {nameError && (
                  <div className="invalid-feedback">{nameError}</div>
                )}
              </div> */}
              <label htmlFor="">Image</label>

              <div className="col-12 d-flex my-1 px-0">
                <input
                  onChange={handleInputChange}
                  className="form-control"
                  type="file"
                  id="change-picture"
                  hidden=""
                  accept="image/png, image/jpeg, image/jpg"
                  name="image"
                
                />
              </div>
              {imageError && <p className="text-danger">{imageError}</p>}
              <button
                type="submit"
                className="btn btn-primary me-1 data-submit waves-effect waves-float waves-light"
              >
                Submit
              </button>
              <Link to="/Banner">
                <button type="reset" className="btn btn-secondary waves-effect">
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddBanner;
