import React, { useEffect, useState } from "react";
import { http } from "../../config/axiosConfig";
import { Eye } from "react-feather";
import { MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { CiPen } from "react-icons/ci";
import Swal from "sweetalert2";

export default function Faq() {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [searchItem, setSearchItem] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const getallFaq = async () => {
    const params = {
      entriesPerPage,
      searchItem,
      currentPage,
    };

    await http
      .get(`Getall_faqs`, { params })
      .then((res) => {
        if (res.data.code === 200) {
          setData(res.data.body.userInfo);
          setTotalPages(res.data.body.totalPages);
        }
      })
      .catch((error) => {
        console.log(error, ".........not Contest");
      });
  };

  useEffect(() => {
    getallFaq();
  }, [entriesPerPage, currentPage, searchItem]);

  const handlePrePage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);
    setCurrentPage(1); // Reset current page when search term changes
  };

  const DeleteFaq = async (id) => {
    const obj = {
      id: id,
    };

    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be delte thsi file!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete",
      });

      if (result.isConfirmed) {
        const res = await http.post(`delete_faqs`, obj);
        if (res.data.code === 200) {
          getallFaq(); // Assuming this function exists and updates UI
         
          return Swal.fire({
            title: "Deleted!",
            text: "Your file has been deleted.",
            icon: "success",
          });
        }
      } else {
        return Swal.fire("Cancelled", "Your FAQ is safe ");
      }
    } catch (error) {
      console.error("Error message: ", error);
      return Swal.fire("Error", "Failed to delete FAQ", "error");
    }
  };

  return (
    <div>
      <div>
        <div className="app-content content ">
          <div className="content-overlay"></div>
          <div className="header-navbar-shadow"></div>
          <div className="content-wrapper container-xxl p-0">
            <div className="content-header row">
              <div className="content-header-left col-md-9 col-12 mb-2"></div>
            </div>
            <div className="content-body">
              <section id="basic-datatable">
                <div className="row">
                  <div className="col-12">
                    <div className="card">
                      <div
                        id="DataTables_Table_0_wrapper"
                        className="dataTables_wrapper dt-bootstrap5 no-footer"
                      >
                        <div className="card-header border-bottom p-1">
                          <div className="head-label">
                            <h6 className="mb-0"> FAQ's</h6>
                          </div>
                          <div className="dt-action-buttons text-end">
                            <div className="dt-buttons d-inline-flex">
                              <Link to="/AddFaq">
                                <button
                                  type="submit"
                                  className="btn btn-primary me-1 data-submit waves-effect waves-float waves-light"
                                >
                                  Add FAQ
                                </button>
                              </Link>
                            </div>
                          </div>
                        </div>

                        <div className="pagination-container">
                          <div className="pagination-select">
                            <label for="">Show</label>
                            <select
                              className="form-select"
                              aria-label="Default select example"
                              onChange={(e) =>
                                setEntriesPerPage(e.target.value)
                              }
                              value={entriesPerPage}
                            >
                              <option value="10">10</option>
                              <option value="20">20</option>
                              <option value="35">35</option>
                              <option value="50">50</option>
                            </select>
                            <label for="">Entry</label>
                          </div>

                          <div className="pagination-search">
                            <label for="search">
                              Search:
                              <input
                                type="search"
                                value={searchItem}
                                onChange={handleInputChange}
                              />
                            </label>
                          </div>
                        </div>
                        <table className="table table-striped table-hover">
                          <thead>
                            <tr>
                              <th scope="col">S.No.</th>
                              <th scope="col">Question</th>

                              <th scope="col">Answer</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {data?.length === 0 ? (
                              <tr>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                  colSpan="6"
                                >
                                  No data available
                                </td>
                              </tr>
                            ) : (
                              data?.map((one, index) => (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{one?.question}</td>
                                  <td>{one?.answer}</td>
                                  <td>
                                    <Link
                                      className="btn btn-outline-primary btn-sm mx-1"
                                      to={`/ViewFaq/${one._id}`}
                                    >
                                      <Eye />
                                    </Link>
                                    {/* <Link
                                      className="btn btn-outline-primary btn-sm mx-1"
                                      to={`/EditFaq/${one._id}`}
                                    >
                                      <CiPen />
                                    </Link> */}
                                    <Link
                                      className="btn btn-outline-danger btn-sm"
                                      onClick={() => DeleteFaq(one._id)}
                                    >
                                      <MdDelete />
                                    </Link>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                        <nav aria-label="...">
                          <ul className="pagination">
                            <li
                              className={`page-item ${
                                currentPage === 1 ? "disabled" : ""
                              }`}
                              onClick={handlePrePage}
                            >
                              <button className="page-link" tabIndex="-1">
                                Previous
                              </button>
                            </li>

                            {[...Array(totalPages).keys()].map((page) => (
                              <li
                                key={page + 1}
                                className={`page-item ${
                                  currentPage === page + 1 ? "active" : ""
                                }`}
                              >
                                <button
                                  className="page-link"
                                  onClick={() => setCurrentPage(page + 1)}
                                >
                                  {page + 1}
                                </button>
                              </li>
                            ))}

                            <li
                              className={`page-item ${
                                currentPage === totalPages ? "disabled" : ""
                              }`}
                              onClick={handleNextPage}
                            >
                              <button className="page-link">Next</button>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
