import React, { useEffect, useState } from "react";
import { Mail } from "react-feather";
import { useParams, useNavigate } from "react-router-dom";
import { http } from "../../config/axiosConfig";
import { toast } from "react-toastify";

export default function ViewContestRule() {
  const [data, setData] = useState([]);
  const id = useParams().id;
  const [questionError, setquestionError] = useState("");
  const [answerError, setanswerError] = useState("");
  const navigate = useNavigate();

  const getoneFaq = async () => {
    const params = {
      _id: id,
    };
    await http
      .get(`get_ContestRule`, { params })
      .then((res) => {
        if (res.data.code === 200) setData(res.data.body);
      })
      .catch((error) => {
        console.log(error, ".......not get");
      });
  };
  useEffect(() => {
    getoneFaq();
  }, []);
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
  };

  const validateInput = () => {
    let valid = true;
    if (!data.question) {
      setquestionError("Question is required");
      valid = false;
    }
    if (!data.answer) {
      setanswerError("answer is required");
      valid = false;
    }

    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateInput()) {
      await http
        .put("Edit_ContestRule", data)
        .then((res) => {
          console.log(res.data);
          if (res.data) {
            toast.success(" Edit successfully");
            navigate("/ContestRule");
          }
        })
        .catch((err) => {
          console.log(err, "========== from .Faq");
        });
    }
  };

  return (
    <div className="app-content content">
      <div className="card card-header">
        <h4 className="card-title mb-0">Contest Rule View</h4>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="card card-body">
            <div className="row row-cols-md-1">
              <div className="form-group">
                <label>Question</label>
                <div className="input-group mb-1">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <Mail />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="question"
                    value={data.question}
                    required=""
                    onChange={handleInputChange}
                  />
                </div>

                <label htmlFor="">Answer</label>
                <textarea
                  name="answer"
                  onChange={handleInputChange}
                  id="Description"
                  className="form-control"
                  rows="3"
                  value={data?.answer || ""}
                  required=""
                ></textarea>
              </div>
            </div>

            <div className="form-group row">
              <div className="col-md-12 col-sm-12 back-button">
                <input
                  type="hidden"
                  className="form-control"
                  name="id"
                  defaultValue={266}
                />
                <a href="/ContestRule">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    Back
                  </button>

                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="btn btn-primary mx-2"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    Save
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
