import React, { useState } from "react";
import { Codesandbox } from "react-feather";

import {  useParams,  useLocation } from "react-router-dom";

const AnswerView = () => {
  const ID = useParams().id;
  
  const location = useLocation();
  
  let question = location?.state.question;
    const option = location?.state.option;
    


  const [data, setData] = useState({
    question: question,
    option: option,
  });
  return (
    <div className="app-content content">
      <div className="card-header card">
        <h4 className="card-title mb-0"> View Question Answer</h4>
      </div>
      <div className="card-body">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="card card-body">
              <div class="form-group row row-cols-md-2">
                <div className="form-group">
                  <label>Question</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <Codesandbox />
                      </div>
                    </div>
                    <textarea
                      type="text"
                      className="form-control"
                      name="question"
                      value={data.question}
                      required=""
                      disabled="true"
                    ></textarea>
                  </div>
                </div>
                <div className="form-group">
                  <label>Answer </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <Codesandbox />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name="answer"
                      value={data.option}
                      required=""
                      disabled="true"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-12 col-sm-12 back-button">
                  <input
                    type="hidden"
                    className="form-control"
                    name="id"
                    defaultValue={266}
                  />
                  <a href="/SurveyUsers">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      Back
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnswerView;
