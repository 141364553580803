import React, { useState, useEffect } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Eye, EyeOff } from "react-feather";
import { http } from "../../config/axiosConfig";
// import logos from "../apis/logo.png";

const Login = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [eye, setEye] = useState(false);

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
    if (name == "email" && emailError && value.trim() !== "") {
      setEmailError("");
    }
    if (name == "password" && passwordError && value.trim() !== "") {
      setPasswordError("");
    }  
  };
  const validateInput = () => {
    let valid = true;
    if (!data.email) {
      setEmailError("Email is required");
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(data.email)) {
      setEmailError(
        "Invalid email format. Please include '@' and '.' in the email."
      );
      valid = false;
    }
    if (!data.password) {
      setPasswordError("Password is required");
      valid = false;
    }

    return valid;
  };

  const handleSubmit = async (e) => {
    // console.log(await http.post(), "pppp");
    e.preventDefault();
    if (validateInput()) {
      try {
        const res = await http.post("/Login", data);

        if (res.data) {
          localStorage.setItem("token", JSON.stringify(res.data.body.token));
          navigate("/Dashboard");
          console.log(res.data, "datadatadata");
        }
      } catch (error) {
        console.log(error, "========== from .login");
        Swal.fire({
          position: "center",
          icon: "error",
          title: "Oops...",
          text: "Incorrect email or password!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    }
  };
  const local = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    if (local) {
      navigate("/Dashboard");
    }
  }, [local]);

  const togglePasswordVisibility = () => {
    setEye(!eye);
  };

  return (
    <>
      <div className="card">
        <div className="content-overlay" />

        <div className="content-wrapper">
          <div className="content-header row"></div>
          <div className="content-body">
            <div className="auth-wrapper auth-v2">
              <div className="auth-inner row m-0">
                {/* Brand logo*/}

                {/* /Brand logo*/}
                {/* Left Text*/}
                <div className="d-none d-lg-flex col-lg-8 align-items-center p-0">
                  <div className="w-100 d-lg-flex align-items-center justify-content-center h-100">
                    <img
                      className="img-fluid logSideImg"
                      src="../../../app-assets/images/pages/pxfuel.jpg"
                      alt="Login V2"
                    />
                  </div>
                </div>
                {/* /Left Text*/}
                {/* Login*/}
                <div className="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
                  <div className="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                    <div className="text-center">
                      <img
                        src="../../../app-assets/images/logo.png"
                        alt="Logo"
                        style={{
                          width: "100px",
                        }}
                      />
                    </div>
                    <form
                      className="auth-login-form mt-2"
                      action="index.html"
                      method="POST"
                      onSubmit={handleSubmit}
                    >
                      <div className="mb-1">
                        <label className="form-label" htmlFor="email">
                          Email
                        </label>
                        <input
                          className={`form-control ${
                            emailError && "is-invalid"
                          }`}
                          id="login-email"
                          type="text"
                          name="email"
                          onChange={(e) => handleInputChange(e)}
                          placeholder="Please enter email"
                          autoFocus=""
                          tabIndex={1}
                        />
                        {emailError && (
                          <div className="invalid-feedback">{emailError}</div>
                        )}
                      </div>

                      <div className="mb-1">
                        <div className="d-flex justify-content-between">
                          <label
                            className="form-label"
                            htmlFor="login-password"
                          >
                            Password
                          </label>
                        </div>
                        <div className="input-group input-group-merge form-password-toggle">
                          <input
                            className={`form-control form-control-merge ${
                              passwordError && "is-invalid"
                            }`}
                            id="login-password"
                            type={eye ? "text" : "password"}
                            name="password"
                            onChange={(e) => handleInputChange(e)}
                            placeholder=" Enter Password"
                            autoFocus=""
                            tabIndex={2}
                          />
                          {passwordError && (
                            <div className="invalid-feedback">
                              {passwordError}
                            </div>
                          )}
                          {data.password && (
                            <span
                              className="input-group-text cursor-pointer"
                              onClick={togglePasswordVisibility}
                            >
                              {eye ? <Eye /> : <EyeOff />}
                            </span>
                          )}
                        </div>
                      </div>

                      <button
                        type="submit"
                        className="btn btn-primary w-100"
                        tabIndex={4}
                      >
                        Sign in
                      </button>
                    </form>
                  </div>
                </div>
                {/* /Login*/}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* END: Content*/}
    </>
  );
};

export default Login;
