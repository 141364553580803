import { User, Phone, Mail, Codesandbox, Calendar } from "react-feather";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import avatar from "../../config/avatar.png";
import axios from "axios";
import { http, httpfile } from "../../config/axiosConfig";

const ReortedUserview = () => {
  const [data, setData] = useState([]);
  console.log(data);
  const id = useParams().id;
  const getoneUsers = async () => {
    const params = {
      _id: id,
    };
    await httpfile
      .get(`getoneReport`, { params })
      .then((res) => {
        if (res.data.code === 200) setData(res.data.body);
        console.log(res.data.body);
      })
      .catch((error) => {
        console.log(error, ".......not get");
      });
  };
  useEffect(() => {
    getoneUsers();
  }, []);

  return (
    <div className="app-content content">
      <div className="card-header card">
        <h4 className="card-title mb-0">Reported Users View</h4>
      </div>
      <div className="card-body">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="card card-body">
              <div class="form-group row row-cols-md-2">
                <div className="form-group">
                  <label>Reported By</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <User />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name=":senderId"
                      value={data?.senderId?.name}
                      required=""
                      disabled="true"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Reported To</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <Mail />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name=":receiverId"
                      value={data?.receiverId?.name}
                      required=""
                      disabled="true"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>message</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <Phone />
                      </div>
                    </div>
                    <textarea
                      type="text"
                      className="form-control"
                      name=":message"
                      value={data.message}
                      required=""
                      disabled="true"
                    >
                      {" "}
                    </textarea>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-md-12 col-sm-12 back-button">
                  <input
                    type="hidden"
                    className="form-control"
                    name="id"
                    defaultValue={266}
                  />
                  <a href="/Report">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      Back
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReortedUserview;
