import React, { useState, useEffect } from "react";
import axios from "axios";
import { Eye } from "react-feather";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import "../Surveys/Survey.css";
import { Link, useParams, useNavigate } from "react-router-dom";
// import ViewOption from "./ViewOption";
import { http } from "../../config/axiosConfig";
import Swal from "sweetalert2";

const ViewAnswer = () => {
  const [data, setData] = useState();
  const { surveyId, userId } = useParams();

  console.log(data)
  const getoneSurveys = async () => {
    
    await http
      .get(`getoneSurveyAnswer`, {
        params: {
          surveyId: surveyId,
          userId: userId,
        },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setData(res.data?.body?.answer);
        }
      })
      .catch((error) => {
        console.log(error, ".........not get");
      });
  };

  useEffect(() => {
    getoneSurveys();
  }, []);

  console.log(data, "=data");

  return (
    <div>
      <div className="app-content content ">
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="content-wrapper container-xxl p-0">
          <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2"></div>
          </div>
          <div className="content-body">
            <section id="basic-datatable">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper dt-bootstrap5 no-footer"
                    >
                      <div className="card-header border-bottom p-1">
                        <div className="head-label">
                          <h6 className="mb-0">Survey's Question</h6>
                        </div>
                       
                      </div>

                     
                      <table className="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th scope="col">S.No.</th>
                            <th scope="col">Question</th>
                            <th scope="col">Answer</th>
                      

                            <th scope="col">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data && data.length === 0 ? (
                            <tr>
                              <td colSpan="4" style={{ textAlign: "center" }}>
                                No data available
                              </td>
                            </tr>
                          ) : Array.isArray(data) ? ( // Check if data is an array
                            data.map((one, index) => (
                              <tr key={one._id}>
                                <th scope="row">{index + 1}</th>
                                <td>
                                  {one.question
                                    ? one.question
                                    : "Unknown"}
                                </td>
                                <td>{one?.option}</td>
              
                                <td>
                                  <Link
                                    className="btn btn-outline-primary me-1 btn-sm"
                                    to={`/viewAnswer`}
                                    state={{
                                      question: one.question,
                                      option: one.option,
                                    }}
                                  >
                                    <Eye />
                                  </Link>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4" style={{ textAlign: "center" }}>
                                Invalid data format
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                     
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAnswer;
