import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { http } from "../../config/axiosConfig";

const MicroDonation = () => {
  const navigate = useNavigate();
  const [data, setData] = useState({
    name: "",
  });

  const getMicroDonation = async () => {
    try {
      const params = {
        id: "66d809da0789fef7fedb8f50", // Replace with actual id
      };
      const response = await http.get(`getMicroDonation`, { params });
      if (response.data.code === 200) {
        setData(response.data.body);
      }
    } catch (error) {
      console.log("Error fetching micro-donation:", error);
    }
  };

  useEffect(() => {
    getMicroDonation();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await http.put(`EditMicroDonation`, {
        id: "66d809da0789fef7fedb8f50",
        name: data.name,
      });
      if (response.data.code === 200) {
        toast.success("Micro-donation updated successfully");
        navigate("/EditMicroDonation");
      } else {
        toast.error(
          "Failed to update micro-donation: " + response.data.message
        );
      }
    } catch (error) {
      console.log("Error updating micro-donation:", error);
      toast.error("Failed to update micro-donation");
    }
  };

  return (
    <div className="app-content content">
      <div className="card card-header">
        <h4 className="card-title mb-0"> Micro-Donation</h4>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="card">
            <form
              className="add-new-user modal-content pt-0"
              onSubmit={handleSubmit}
            >
              <div className="modal-body flex-grow-1">
                <div className="mb-1">
                <label>Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    placeholder="Enter Name"
                    name="name"
                    value={data.name}
                    onChange={handleInputChange}
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary me-1 data-submit waves-effect waves-float waves-light"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MicroDonation;
