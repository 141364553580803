import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { http } from "../../config/axiosConfig";

const ViewOption = () => {
  const location = useLocation();
  const { id } = useParams();
  const navigate = useNavigate();

  const servayId = location.state?.ID;
  const optionsData = location.state?.data.Options || [];
  const surveyData = location.state?.data;

  const [option, setOption] = useState({
    id: id,
    SurveyName: surveyData?.SurveyName || "",
    options: optionsData
      .filter((option) => option.value) // Filter out options with no value
      .map((option, index) => ({
        id: option._id,
        value: option.value,
      })),
  });

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const updatedOptions = [...option.options];
    updatedOptions[index].value = value;
    setOption({ ...option, options: updatedOptions });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("id", option.id);
    formData.append("SurveyName", option.SurveyName);
    formData.append("options", JSON.stringify(option.options));

    try {
      await http.put("editSurveyOptions", option);
      toast.success("Edit successfully");
      navigate(`/ViewSurvey/${servayId}`);
    } catch (error) {
      console.error(error);
      toast.error("Error occurred while saving.");
    }
  };

  return (
    <div className="app-content content">
      <div className="content-wrapper container-xxl p-0">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title mb-0">Question View</h4>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <label htmlFor="surveyName">Survey Name</label>
                <textarea
                  id="surveyName"
                  className="form-control"
                  name="SurveyName"
                  rows="3"
                  value={option.SurveyName}
                  onChange={(e) =>
                    setOption({ ...option, SurveyName: e.target.value })
                  }
                />
              </div>
              {option.options.map((opt, index) => (
                <div className="form-group" key={index}>
                  <label>Option {index + 1}</label>
                  <input
                    type="text"
                    className="form-control"
                    value={opt.value}
                    onChange={(e) => handleInputChange(e, index)}
                  />
                </div>
              ))}
              <div className="form-group">
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
                <button
                  type="button"
                  className="btn btn-secondary ml-2"
                  onClick={() => navigate(`/ViewSurvey/${servayId}`)}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewOption;
