import React, { useState, useEffect } from "react";
import "../Contest/Contest.css";
import { Link } from "react-router-dom";
import { Eye } from "react-feather";
import { http } from "../../config/axiosConfig";
import { toast } from "react-toastify";
import { MdDelete } from "react-icons/md";
import Swal from "sweetalert2";

const Contest = () => {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [searchItem, setSearchItem] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  // show all users in table
  const getallContest = async () => {
    const params = {
      entriesPerPage,
      searchItem,
      currentPage,
    };
    console.log(data, "jhfkdhfoj");
    await http
      .get(`ContestGet`, { params })
      .then((res) => {
        if (res.data.code === 200) {
          setData(res.data.body.userInfo);
          setTotalPages(res.data.body.totalPages);
        }
      })
      .catch((error) => {
        console.log(error, ".........not Contest");
      });
  };

  useEffect(() => {
    getallContest();
  }, [entriesPerPage, currentPage, searchItem]);

  const handlePrePage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);
    setCurrentPage(1); // Reset current page when search term changes
  };
  const DeleteSurvey = async (id) => {
    const obj1 = {
      id: id,
    };

    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const res = await http.post(`DeleteContest`, obj1);
        if (res.data.code === 200) {
          getallContest(); // Assuming this function exists and updates UI

          return Swal.fire({
            title: "Deleted!",
            text: "Your survey has been deleted.",
            icon: "success",
          });
        }
      } else {
        return Swal.fire("Cancelled", "Your survey is safe :)", "info");
      }
    } catch (error) {
      console.error("Error message: ", error);
      return Swal.fire("Error", "Failed to delete survey", "error");
    }
  };

  return (
    <div>
      <div className="app-content content ">
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="content-wrapper container-xxl p-0">
          <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2"></div>
          </div>
          <div className="content-body">
            <section id="basic-datatable">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper dt-bootstrap5 no-footer"
                    >
                      <div className="card-header border-bottom p-1">
                        <div className="head-label">
                          <h6 className="mb-0">Contests</h6>
                        </div>
                        <div className="dt-action-buttons text-end">
                          <div className="dt-buttons d-inline-flex">
                            <Link to="/AddContest">
                              <button
                                type="submit"
                                className="btn btn-primary me-1 data-submit waves-effect waves-float waves-light"
                              >
                                Add Contest
                              </button>
                            </Link>
                          </div>
                        </div>
                      </div>

                      <div className="pagination-container">
                        <div className="pagination-select">
                          <label for="">Show</label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setEntriesPerPage(e.target.value)}
                            value={entriesPerPage}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="35">35</option>
                            <option value="50">50</option>
                          </select>
                          <label for="">Entry</label>
                        </div>

                        <div className="pagination-search">
                          <label for="search">
                            Search:
                            <input
                              type="search"
                              value={searchItem}
                              onChange={handleInputChange}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table table-striped table-hover">
                          <thead>
                            <tr>
                              <th scope="col">S.No.</th>
                              <th scope="col">Title</th>
                              <th scope="col">Images</th>
                              <th scope="col">Description</th>
                              <th scope="col">Total Participants</th>
                              <th scope="col">current Participants</th>
                              <th scope="col">Start Date</th>
                              <th scope="col">Action</th>
                            </tr>
                          </thead>

                          <tbody>
                            {data.length === 0 ? (
                              <tr>
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                  colSpan="6"
                                >
                                  No data available
                                </td>
                              </tr>
                            ) : (
                              data.map((one, index) => (
                                <tr key={index}>
                                  <th scope="row">{index + 1}</th>
                                  <td>{one?.Title}</td>
                                  <td>
                                    {" "}
                                    <img
                                      src={`${process.env.REACT_APP_BASEAPI_URL}images/Charity/${one?.files}`}
                                      alt="users avatar"
                                      className="user-avatar users-avatar-shadow rounded me-2 my-25 cursor-pointer"
                                      height={45}
                                      width={45}
                                    />
                                  </td>
                                  <td>{one?.Description}</td>
                                  <td>{one?.TotalUsers}</td>
                                  <td>{one?.Participants.length} </td>
                                  <td>
                                    {one && one.StartDate
                                      ? new Date(
                                          one.StartDate
                                        ).toLocaleDateString("en-GB", {
                                          day: "numeric",
                                          month: "short",
                                          year: "numeric",
                                        })
                                      : ""}
                                  </td>
                                  <td>
                                    <Link
                                      className="btn btn-outline-primary btn-sm mx-1"
                                      to={`/ViewContest/${one._id}`}
                                    >
                                      <Eye />
                                    </Link>

                                    <Link
                                      className="btn btn-outline-danger btn-sm"
                                      onClick={() => DeleteSurvey(one._id)}
                                    >
                                      <MdDelete />
                                    </Link>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                      <nav aria-label="...">
                        <ul className="pagination">
                          <li
                            className={`page-item ${
                              currentPage === 1 ? "disabled" : ""
                            }`}
                            onClick={handlePrePage}
                          >
                            <button className="page-link" tabIndex="-1">
                              Previous
                            </button>
                          </li>

                          {[...Array(totalPages).keys()].map((page) => (
                            <li
                              key={page + 1}
                              className={`page-item ${
                                currentPage === page + 1 ? "active" : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() => setCurrentPage(page + 1)}
                              >
                                {page + 1}
                              </button>
                            </li>
                          ))}

                          <li
                            className={`page-item ${
                              currentPage === totalPages ? "disabled" : ""
                            }`}
                            onClick={handleNextPage}
                          >
                            <button className="page-link">Next</button>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contest;
