import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";

import "../User/User.css";
import { Link } from "react-router-dom";
import axios from "axios";
// import { toast } from "react-toastify";
import { Eye } from "react-feather";
import { http, httpfile } from "../../config/axiosConfig";
import Swal from "sweetalert2";
const User = () => {
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState();
  const [searchItem, setSearchItem] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  // show all users in table
  const getallusers = async () => {
    const params = {
      entriesPerPage,
      searchItem,
      currentPage,
    };

    await httpfile
      .get(`GetallUsers`, { params })
      .then((res) => {
        if (res.data.code === 200) {
          setData(res.data.body.userInfo);
          setTotalPages(res.data.body.totalPages);
        }
      })
      .catch((error) => {
        console.log(error, ".........not get");
      });
  };

  useEffect(() => {
    getallusers();
  }, [entriesPerPage, currentPage, searchItem]);

  const handlePrePage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);
    setCurrentPage(1); // Reset current page when search term changes
  };
  const updateuser = async (status, id) => {
    // Toggle the status between 0 and 1
    const newStatus = status === 0 ? 1 : 0;

    // Create an object with the id and the new status
    const obj = {
      id: id,
      status: newStatus,
    };

    try {
      // Show a success notification using SweetAlert
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Status Updated",
        showConfirmButton: false,
        timer: 1500,
      });

      let res = await httpfile.put(`updateStatus`, obj);

      if (res.data.code === 200) {
        getallusers(); // Assuming this function retrieves all users after an update
      }
    } catch (error) {
      // Log any errors that occur during the process
      console.error("error updating message ", error);
    }
  };

  return (
    <div>
      <div className="app-content content ">
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="content-wrapper container-xxl p-0">
          <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2"></div>
          </div>
          <div className="content-body">
            <section id="basic-datatable">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper dt-bootstrap5 no-footer"
                    >
                      <div className="card-header border-bottom p-1">
                        <div className="head-label">
                          <h6 className="mb-0">Users </h6>
                        </div>
                      </div>

                      <div className="pagination-container">
                        <div className="pagination-select">
                          <label for="">Show</label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setEntriesPerPage(e.target.value)}
                            value={entriesPerPage}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="35">35</option>
                            <option value="50">50</option>
                          </select>
                          <label for="">Entry</label>
                        </div>

                        <div className="pagination-search">
                          <label for="search">
                            Search:
                            <input
                              type="search"
                              value={searchItem}
                              onChange={handleInputChange}
                            />
                          </label>
                        </div>
                      </div>
                      <table
                        className="table table-striped table-hover"
                        style={{ fontSize: "small" }}
                      >
                        <thead>
                          <tr>
                            <th scope="col">S.No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Image</th>

                            <th scope="col">Phone Number</th>
                            <th scope="col">Location</th>
                            <th scope="col">PostalCode</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data.length === 0 ? (
                            <tr>
                              <td
                                style={{
                                  textAlign: "center",
                                }}
                                colSpan="6"
                              >
                                No data available
                              </td>
                            </tr>
                          ) : (
                            data.map((one, index) => (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{one?.name}</td>
                                <td>
                                  <img
                                    src={`${process.env.REACT_APP_BASEAPI_URL}images/Profile/${one?.image[0]}`}
                                    alt="user avatar"
                                    className="user-avatar users-avatar-shadow rounded me-2 my-25 cursor-pointer"
                                    height={45}
                                    width={45}
                                  />
                                </td>
                                <td>{one?.phoneNnumberWithCode}</td>
                                <td>{one?.Location?.LocationName}</td>
                                <td>{one?.Postalcode}</td>
                                <td>
                                  {one?.status === 0 ? (
                                    <button
                                      type="button"
                                      className="btn btn-success"
                                      onClick={(e) =>
                                        updateuser(one.status, one._id)
                                      }
                                    >
                                      Active
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={(e) =>
                                        updateuser(one.status, one._id)
                                      }
                                    >
                                      In-active
                                    </button>
                                  )}
                                </td>
                                <td>
                                  <Link
                                    className="btn btn-outline-primary btn-sm "
                                    to={`/ViewUser/${one._id}`}
                                  >
                                    <Eye />
                                  </Link>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                      <nav aria-label="...">
                        <ul className="pagination">
                          <li
                            className={`page-item ${
                              currentPage === 1 ? "disabled" : ""
                            }`}
                            onClick={handlePrePage}
                          >
                            <button className="page-link" tabIndex="-1">
                              Previous
                            </button>
                          </li>

                          {[...Array(totalPages).keys()].map((page) => (
                            <li
                              key={page + 1}
                              className={`page-item ${
                                currentPage === page + 1 ? "active" : ""
                              }`}
                            >
                              <button
                                className="page-link"
                                onClick={() => setCurrentPage(page + 1)}
                              >
                                {page + 1}
                              </button>
                            </li>
                          ))}

                          <li
                            className={`page-item ${
                              currentPage === totalPages ? "disabled" : ""
                            }`}
                            onClick={handleNextPage}
                          >
                            <button className="page-link">Next</button>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
