import React, { useEffect, useState } from "react";
import { FaHandHoldingHeart } from "react-icons/fa6";
import { FcSurvey } from "react-icons/fc";
import { TfiCup } from "react-icons/tfi";
import { FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import { http } from "../../config/axiosConfig";

const Dashboard = () => {
  const [data, setData] = useState({});
  const getDashboard = async (e) => {
    await http
      .get("Dashboard")
      .then((res) => {
        if (res.data.code === 200) {
          setData(res.data.body);
        }
      })
      .catch((error) => {
        console.log(error, ".........not get");
      });
  };
  useEffect(() => {
    getDashboard();
  }, []);
  return (
    <div className="app-content content ">
      <div className="card-header card">
        <h4 className="card-title mb-0">Dashboard</h4>
      </div>

      <div className="content-wrapper container-xxl p-0">
        <div className="content-body">
          <section id="dashboard-ecommerce">
            <div className="row match-height">
              <div className="col-xl-12 col-12">
                <div className="row">
                  <div className="col-xl-3 col-sm-6 col-12 mb-2 mb-xl-0">
                    <Link className="d-flex align-items-center" to="/User">
                      <div className="dash_card">
                        <div className="avatar bg-light-info me-2">
                          <div className="avatar-content">
                            <FaUser />
                          </div>
                        </div>
                        <div className="my-auto">
                          <h4 className="fw-bolder mb-0"> {data.userCount}</h4>
                          <p className="card-text mb-0 text-info">Users</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-xl-3 col-sm-6 col-12 mb-2 mb-xl-0">
                    <Link className="d-flex align-items-center" to="/Survey">
                      <div className="dash_card">
                        <div className="avatar bg-light-primary me-2">
                          <div className="avatar-content logside_img">
                            <FcSurvey />
                          </div>
                        </div>
                        <div className="my-auto">
                          <h4 className="fw-bolder mb-0">{data.surveyCount}</h4>
                          <p className="card-text mb-0 text-primary">
                            Survey's
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-xl-3 col-sm-6 col-12 mb-2 mb-sm-0">
                    <Link className="d-flex align-items-center" to="/Charity">
                      <div className="dash_card">
                        <div className="avatar bg-light-danger me-2">
                          <div className="avatar-content">
                            <FaHandHoldingHeart />
                          </div>
                        </div>
                        <div className="my-auto">
                          <h4 className="fw-bolder mb-0">
                            {data.charityCount}
                          </h4>
                          <p className="card-text mb-0 text-danger">Charity</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-xl-3 col-sm-6 col-12">
                    <Link className="d-flex align-items-center" to="/Contest">
                      <div className="dash_card">
                        <div className="avatar bg-light-success me-2">
                          <div className="avatar-content">
                            <TfiCup />
                          </div>
                        </div>
                        <div className="my-auto">
                          <h4 className="fw-bolder mb-0">
                            {data.contestCount}
                          </h4>
                          <p className="card-text mb-0 text-success">
                            Contest's
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            {/* <ReactApexChart
              options={{
                chart: {
                  height: 350,
                  type: "line",
                  zoom: {
                    enabled: false,
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                stroke: {
                  curve: "straight",
                },
                title: {
                  text: "Booking Requests",
                  align: "left",
                },
                grid: {
                  row: {
                    colors: ["#f3f3f3", "transparent"],
                    opacity: 0.5,
                  },
                },
                xaxis: {
                  categories: [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                  ],
                },
              }}
              series={[
                {
                  name: "Booking",
                  data: [
                    (data && data.BookingCount) || 0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                    0,
                  ],
                },
              ]}
              type="line"
              height={350}
            /> */}
          </section>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
