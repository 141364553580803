import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";


import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { http, httpfile } from "../../config/axiosConfig";

const AddCharity = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const [TitleError, setTitleError] = useState("");
  const [DescriptionError, setDescriptionError] = useState("");
  const [TargetError, setTargetError] = useState("");
  const [CharityurlError, setCharityurlError] = useState("");

  // const [donationlastDate, setdonationlastDateError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (e.target.files) {
      setData({ ...data, [name]: e.target.files[0] });
    } else {
      setData({ ...data, [name]: value });
    }
    if (TitleError && value.trim() !== "") {
      setTitleError("");
    }
    if (DescriptionError && value.trim() !== "") {
      setDescriptionError("");
    }
    if (TargetError && value.trim() !== "") {
      setTargetError("");
    }
      if (CharityurlError && value.trim() !== "") {
        setCharityurlError("");
      }
  };
  const validateInput = () => {
    let valid = true;
    if (!data.Title || data.Title.trim() === "") {
      setTitleError("Title is required");
      valid = false;
    } else {
      setTitleError("");
    }
    if (!data.Description || data.Description.trim() === "") {
      setDescriptionError("Description is required");
      valid = false;
    } else {
      setDescriptionError("");
    }
    if (!data.Target || data.Target.trim() === "") {
      setTargetError("Target is required");
      valid = false;
    } else {
      setTargetError("");
    }
    if (!data.Charityurl || data.Charityurl.trim() === "") {
      setCharityurlError("CharityurlError is required");
      valid = false;
    } else {
      setCharityurlError("");
    }
    
    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateInput()) {
      await httpfile
        .post(`CharityCreate`, data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          console.log(res.data);
          if (res.data) {
            navigate("/Charity");
            toast.success(" Add Charity");
          }
        })
        .catch((err) => {
          console.log(err, "========== from .Charity");
        });
    }
  };
  return (
    <div className="app-content content ">
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow"></div>
      <div className="content-wrapper container-xxl p-0">
        <div className="card-header card">
          <h4 className="mb-0 card-title">Add Charity</h4>
        </div>
        <div className="card">
          <form
            className="add-new-user modal-content pt-0"
            noValidate="novalidate"
            onSubmit={handleSubmit}
          >
            <div className="modal-body flex-grow-1">
              <div className="mb-1">
                <label>Title</label>
                <input
                  type="text"
                  className={`form-control ${TitleError && "is-invalid"}`}
                  id="basic-icon-default-fullname"
                  placeholder="Title"
                  onChange={(e) => handleInputChange(e)}
                  name="Title"
                  aria-label="John Doe"
                  tabIndex={1}
                  aria-describedby="basic-icon-default-fullname2"
                />
                {TitleError && (
                  <div className="invalid-feedback">{TitleError}</div>
                )}
              </div>
            </div>
            <div className="modal-body flex-grow-1">
              <div className="mb-1">
                <label>Description</label>
                <input
                  type="text"
                  className={`form-control ${DescriptionError && "is-invalid"}`}
                  id="basic-icon-default-fullname"
                  placeholder="Description"
                  onChange={(e) => handleInputChange(e)}
                  name="Description"
                  aria-label="John Doe"
                  tabIndex={1}
                  aria-describedby="basic-icon-default-fullname2"
                />
                {DescriptionError && (
                  <div className="invalid-feedback">{DescriptionError}</div>
                )}
              </div>{" "}
              <div className="mb-1">
                <label htmlFor="DonationLastDate">Late Date of Donation</label>
                <input
                  type="date"
                  className="form-control"
                  id="DonationLastDate"
                  placeholder="Late Date of Donation"
                  onChange={(e) => handleInputChange(e)}
                  name="DonationLastDate"
                  aria-label="DonationLastDate"
                  aria-describedby="basic-icon-default-fullname2"
                />
              </div>
              <div className="mb-1">
                <label>Target</label>

                <input
                  type="number"
                  className={`form-control ${TargetError && "is-invalid"}`}
                  id="basic-icon-default-fullname"
                  placeholder="Target"
                  onChange={(e) => handleInputChange(e)}
                  name="Target"
                  aria-label="John Doe"
                  tabIndex={1}
                  aria-describedby="basic-icon-default-fullname2"
                />
                {TargetError && (
                  <div className="invalid-feedback">{TargetError}</div>
                )}
              </div>
              <div className="mb-1">
                <label>Charity Url</label>

                <input
                  type="text"
                  className={`form-control ${CharityurlError && "is-invalid"}`}
                  id="basic-icon-default-fullname"
                  placeholder="Charityurl"
                  onChange={(e) => handleInputChange(e)}
                  name="Charityurl"
                  aria-label="John Doe"
                  tabIndex={1}
                  aria-describedby="basic-icon-default-fullname2"
                />
                {CharityurlError && (
                  <div className="invalid-feedback">{CharityurlError}</div>
                )}
              </div>
              <div>
                <label>Image</label>
                <div
                  className="col-12 d-flex mt-1 px-0"
                  style={{
                    marginBottom: "10px",
                  }}
                >
                  <input
                    onChange={handleInputChange}
                    className="form-control"
                    type="file"
                    id="change-picture"
                    hidden=""
                    accept="image/png, image/jpeg, image/jpg"
                    name="image"
                    style={{ padding: "0.200rem" }}
                  />
                </div>
              </div>
              <h4 className="mb-1 mt-2 text-decoration-underline">Optional</h4>
              <div className="mb-1">
                <label>Bank Name </label>
                <input
                  type="text"
                  className="form-control"
                  id="basic-icon-default-fullname"
                  placeholder="Bank Name"
                  onChange={(e) => handleInputChange(e)}
                  name="BankName" // Make sure this matches the property name expected by your backend
                  aria-label="Bank Name"
                  aria-describedby="basic-icon-default-fullname2"
                />
              </div>
              <div className="mb-1">
                <label>Bank Account Number</label>
                <input
                  type="Number"
                  className="form-control"
                  id="basic-icon-default-fullname"
                  placeholder="Enter Bank Account Number"
                  onChange={(e) => handleInputChange(e)}
                  name="BankAccountNumber" // Make sure this matches the property name expected by your backend
                  aria-label="BankAccountNumber"
                  aria-describedby="basic-icon-default-fullname2"
                />
              </div>{" "}
              <div className="mb-1">
                <label>SWIFT Code</label>
                <input
                  type="text"
                  className="form-control"
                  id="basic-icon-default-fullname"
                  placeholder="SWIFT Code"
                  onChange={(e) => handleInputChange(e)}
                  name="BankIFSC" // Make sure this matches the property name expected by your backend
                  aria-label="BankIFSC"
                  aria-describedby="basic-icon-default-fullname2"
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary me-1 data-submit waves-effect waves-float waves-light"
              >
                Submit
              </button>
              <Link to="/Charity">
                <button
                  type="reset"
                  className="btn btn-outline-secondary waves-effect"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCharity;
