import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { http } from "../../config/axiosConfig";

const AddQuestion = () => {
  const [data, setData] = useState({
    SurveyName: "",
    Description: "",
    Options: [],
  });
  const navigate = useNavigate();
  const [QuestionError, setQuestionError] = useState("");
  const [optionsCount, setOptionsCount] = useState(); // State variable to track number of options

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;

    const newOptions = [...data.Options];
    newOptions[index] = { value: value };

    if (name === "SurveyName") {
      setData({ ...data, SurveyName: value });
    } else {
      setData({ ...data, Options: newOptions });
    }

    if (name === "SurveyName" && QuestionError && value.trim() !== "") {
      setQuestionError("");
    }
  };

  const validateInput = () => {
    let valid = true;

    if (!data.SurveyName || data.SurveyName.trim() === "") {
      setQuestionError("SurveyName is required");
      valid = false;
    } else {
      setQuestionError("");
    }

    data.Options.forEach((option) => {
      if (!option.Option || option.Option.trim() === "") {
        valid = false;
      }
    });

    return valid;
  };

  // const newData = JSON.stringify(data);

  const datau = {
    _id: useParams().id,
    Surveys: data,
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(datau, "dddddddddddddd");
    // return;

    // if (validateInput()) {
    await http
      .put(`UpdateSurvey`, datau)
      .then((res) => {
        if (res.data) {
          navigate(-1);
          toast.success("Question added successfully");
        }
      })
      .catch((err) => {
        console.log(err, "========== from .Survey");
      });
    // }
  };

  const addOption = () => {
    setOptionsCount((prevCount) => prevCount + 1);
  };

  return (
    <div className="app-content content ">
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow"></div>
      <div className="content-wrapper container-xxl p-0">
        <div className="card-header card">
          <h4 className="mb-0 card-title"> Add Questions</h4>
        </div>
        <div className="card">
          <form
            className="add-new-user modal-content pt-0"
            noValidate="novalidate"
            onSubmit={handleSubmit}
          >
            <div className="modal-body flex-grow-1">
              <div className="mb-1">
                <label>Question</label>
                <input
                  type="text"
                  className={`form-control ${QuestionError && "is-invalid"}`}
                  id="basic-icon-default-question"
                  placeholder="Add Question"
                  onChange={(e) => handleInputChange(e)}
                  name="SurveyName"
                  aria-label="Question"
                  tabIndex={3}
                  aria-describedby="basic-icon-default-question2"
                />
                {QuestionError && (
                  <div className="invalid-feedback">{QuestionError}</div>
                )}
              </div>
              <div className="mb-1">
                <label>Options</label>
                {[...Array(optionsCount)].map((_, index) => (
                  <input
                    key={index}
                    type="text"
                    className={`mb-1 form-control`}
                    id={`basic-icon-default-options${index}`}
                    placeholder={`Option ${index + 1}`}
                    onChange={(e) => handleInputChange(e, index)}
                    name={`Options${index}`}
                    aria-label={`Option ${index + 1}`}
                    tabIndex={5 + index}
                    aria-describedby={`basic-icon-default-options${index}2`}
                  />
                ))}
              </div>
              <button
                type="button"
                className="btn btn-primary me-1 data-submit waves-effect waves-float waves-light"
                onClick={addOption}
              >
                Add more option
              </button>
              <button
                type="submit"
                className="btn btn-primary me-1 data-submit waves-effect waves-float waves-light"
              >
                Submit
              </button>
              <Link to="/Survey" className="">
                <button type="reset" className="btn btn-secondary">
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddQuestion;

