import React, { useState, useEffect } from "react";
import axios from "axios";
import { Eye } from "react-feather";
import { MdDelete } from "react-icons/md";
import { toast } from "react-toastify";
import "../Surveys/Survey.css";
import { Link, useParams, useNavigate } from "react-router-dom";
// import ViewOption from "./ViewOption";
import { http } from "../../config/axiosConfig";
import Swal from "sweetalert2";

const ViewSurveyUser = () => {
  const [data, setData] = useState([]);
  const surveyId = useParams().id;
  const getSurveyUsers = async () => {
    try {
      console.log(data, "jjnjkfgjlfkgjfklghfl");
      
    
      const response = await http.get("SurveyUsersget", {
        params: {
          surveyId: surveyId,
        },
      });
      if (response.data.code === 200) {
        setData(response.data.body);
      }
    } catch (error) {
      console.error("Failed to fetch survey users:", error);
    }
  };

  useEffect(() => {
    getSurveyUsers();
  }, [surveyId]);

  return (
    <div>
      <div className="app-content content">
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="content-wrapper container-xxl p-0">
          <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2"></div>
          </div>
          <div className="content-body">
            <section id="basic-datatable">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper dt-bootstrap5 no-footer"
                    >
                      <div className="card-header border-bottom p-1">
                        <div className="head-label">
                          <h6 className="mb-0">Survey's Users</h6>
                        </div>
                      </div>

                      <table className="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th scope="col">S.No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone Number</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data.length === 0 ? (
                            <tr>
                              <td colSpan="5" style={{ textAlign: "center" }}>
                                No data available
                              </td>
                            </tr>
                          ) : (
                              data.map((one, index) => (
                              <tr key={one._id}>
                                <th scope="row">{index + 1}</th>
                                <td>
                                  {one.userId ? one.userId.name : "Unknown"}
                                </td>
                                <td>{one?.userId?.email}</td>
                                <td>{one?.userId?.phoneNnumberWithCode}</td>
                                <td>
                                  <Link
                                    className="btn btn-outline-primary me-1 btn-sm"
                                    to={`/viewAnswer/${surveyId}/${one.userId._id}`}
                                  >
                                    <Eye />
                                  </Link>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewSurveyUser;
