import React, { useEffect, useState } from "react";
import { Calendar } from "react-feather";
import { useParams, useNavigate } from "react-router-dom";
import { http, httpfile } from "../../config/axiosConfig";
import { toast } from "react-toastify";

export const CharityDonationPoint = () => {
  const [data, setData] = useState({});

  const navigate = useNavigate();

  const [error, setError] = useState("");

  const fetchCharityDonationPoint = async () => {
    try {
      const response = await httpfile.get(`getCharityDonationpoint`);
      if (response.data.code === 200) {
        setData(response.data.body);

        console.log(response.data.body, "response.data.body");
      } else {
        setError("Failed to fetch data");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data");
    }
  };

  useEffect(() => {
    fetchCharityDonationPoint();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await http.put(`update_CharityDonationPoint`, {
        _id: data._id,
        CharityDonationPoint: data.CharityDonationPoint,
      });
      if (response.data.code === 200) {
        toast.success("Charity donation point updated successfully");
        navigate("/CharityDonationPoint");
      } else {
        toast.error("Failed to update charity donation point");
      }
    } catch (error) {
      console.log(error);
      toast.error("Error updating charity donation point");
    }
  };

  return (
    <div className="app-content content">
      <div className="card card-header">
        <h4 className="card-title mb-0">Update Charity Donation Point</h4>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="card card-body">
            <div className="row row-cols-md-1">
              <div className="form-group">
                <label>Charity Donation Point</label>
                <div className="input-group mb-1">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <Calendar />
                    </div>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    name="CharityDonationPoint"
                    value={data.CharityDonationPoint || ""}
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            </div>

            <div className="form-group row">
              <div className="col-md-12 col-sm-12 back-button">
                <a href="/CharityDonationPoints">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    style={{ marginTop: "10px" }}
                  >
                    Back
                  </button>
                </a>

                <button
                  onClick={handleSubmit}
                  type="submit"
                  className="btn btn-primary mx-2"
                  style={{ marginTop: "10px" }}
                >
                  Save
                </button>
              </div>
            </div>
            {error && <div className="alert alert-danger">{error}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};
