import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { http } from "../../config/axiosConfig";

const EditFaq = () => {
  const ID = useParams().id;
  const [data, setData] = useState({ id: ID });
  const navigate = useNavigate();

  const [questionError, setquestionError] = useState("");
  const [answerError, setanswerError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const validateInput = () => {
    let valid = true;
    if (!data.question) {
      setquestionError("Question is required");
      valid = false;
    } if (!data.answer) {
      setanswerError("answer is required");
      valid = false;
    }

    return valid;
  };
  console.log(data, "hjasdgfvkjsdyhe");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const param = {
      // _id:id
    };
    console.log(data, "ddhdghdhd");
    if (validateInput()) {
      await http
        .put("UpdateFaq", data)
        .then((res) => {
          console.log(res.data);
          if (res.data) {
            toast.success(" Edit successfully");
            navigate("/Faq");
          }
        })
        .catch((err) => {
          console.log(err, "========== from .Faq");
        });
    }
  };

  const handleChnage = (e) => {
    const { name, value } = e.target;
    if (e.target.files) {
      setData({ ...data, [name]: e.target.files[0] });
    } else {
      setData({ ...data, [name]: value });
    }
  };
  return (
    <>
      <div className="app-content content">
        <div className="card card-header">
          <h4 className="card-title mb-0">Edit Preferences</h4>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="card">
              <form
                className="add-new-user modal-content pt-0"
                noValidate="novalidate"
                onSubmit={handleSubmit}
              >
                <div className="modal-body flex-grow-1">
                  <label>Question</label>
                  <div className="mb-1">
                    <input
                      type="text"
                      className={`form-control ${
                        questionError && "is-invalid"
                      }`}
                      id="basic-icon-default-fullname"
                      placeholder="Enter Name"
                      onChange={(e) => handleInputChange(e)}
                      name="question"
                      aria-label="Enter Name"
                      tabIndex={1}
                      aria-describedby="basic-icon-default-fullname2"
                    />
                    {questionError && (
                      <div className="invalid-feedback">{questionError}</div>
                    )}
                  </div>

               </div>
                <div className="modal-body flex-grow-1">
                  <label>Answer</label>
                  <div className="mb-1">
                    <input
                      type="text"
                      className={`form-control ${answerError && "is-invalid"}`}
                      id="basic-icon-default-fullname"
                      placeholder="Enter answer"
                      onChange={(e) => handleInputChange(e)}
                      name="answer"
                      aria-label="Enter answer"
                      tabIndex={1}
                      aria-describedby="basic-icon-default-fullname2"
                    />
                    {answerError && (
                      <div className="invalid-feedback">{answerError}</div>
                    )}
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary me-1 data-submit waves-effect waves-float waves-light"
                  >
                    Submit
                  </button>
                  <Link to="/Preferences">
                    <button
                      type="reset"
                      className="btn btn-outline-secondary waves-effect"
                    >
                      Cancel
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Modal to add new user Ends*/}
    </>
  );
};

export default EditFaq;
