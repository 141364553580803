import { User, Link, Mail, Codesandbox, Calendar } from "react-feather";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { http, httpfile } from "../../config/axiosConfig";
import { toast } from "react-toastify";
const ViewCharity = () => {
  const [data, setData] = useState([]);
  const id = useParams().id;

  const [titleError, settitleError] = useState("");
  const [DescriptionError, setDescriptionError] = useState("");
  const [TargetError, setTargetError] = useState("");
  const [CharityurlError, setCharityurlError] = useState("");
  

  const navigate = useNavigate();

  const getoneUsers = async () => {
    const params = {
      _id: id,
    };
    await http
      .get(`ViewCharity`, { params })
      .then((res) => {
        if (res.data.code === 200) setData(res.data.body);
      })
      .catch((error) => {
        console.log(error, ".......not get");
      });
  };
  useEffect(() => {
    getoneUsers();
  }, []);

  const handleChnage = (e) => {
    const { name, value } = e.target;
    if (e.target.files) {
      setData({ ...data, [name]: e.target.files[0] });
    } else {
      setData({ ...data, [name]: value });
    }
    if (titleError && value.trim() !== "") {
      settitleError("");
    }
    if (DescriptionError && value.trim() !== "") {
      setDescriptionError("");
    }
    if (TargetError && value.trim() !== "") {
      setTargetError("");
    }
    if (CharityurlError && value.trim() !== "") {
      setCharityurlError("");
    }
  };

  const validateInput = () => {
    let valid = true;
    if (!data.Title) {
      settitleError("title is required");
      valid = false;
    }
    if (!data.Description) {
      setDescriptionError("Description is required");
      valid = false;
    }
    if (!data.Target) {
      setTargetError("Target is required");
      valid = false;
    }
    if (!data.Charityurl) {
      setTargetError("Charityurl is required");
      valid = false;
    }

    return valid;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateInput()) {
      await httpfile
        .put("EditCahrity", data)
        .then((res) => {
          console.log(res.data);
          if (res.data) {
            toast.success(" Edit successfully");
            navigate("/Charity");
          }
        })

        .catch((err) => {
          console.log(err, "========== from .Charity");
        });
    }
  };

  return (
    <div className="app-content content">
      <div className="card card-header">
        <h4 className="card-title mb-0">Charity View</h4>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="card card-body">
            <div className="row row-cols-md-2">
              <div className="form-group w-100">
                <label>Image</label>
                <div>
                  <img
                    src={`${process.env.REACT_APP_BASEAPI_URL}images/Charity/${data?.files}`}
                    alt="No image"
                    className="user-avatar users-avatar-shadow rounded me-2 my-25 cursor-pointer"
                    height={75}
                    width={75}
                    style={{ border: "double" }}
                  />
                  <div className="col-12 d-flex mt-1 px-0">
                    <input
                      onChange={handleChnage}
                      className="form-control mb-1"
                      type="file"
                      id="change-picture"
                      hidden=""
                      accept="image/png, image/jpeg, image/jpg"
                      name="image"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Title</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <Mail />
                    </div>
                  </div>
                  <input
                    type="text"
                    className={`form-control ${titleError ? "is-invalid" : ""}`}
                    name="Title"
                    value={data.Title || ""}
                    required=""
                    onChange={handleChnage}
                  />
                  {titleError && (
                    <div className="invalid-feedback">{titleError}</div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Charityurl</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <Link />
                    </div>
                  </div>
                  <input
                    type="text"
                    className={`form-control ${
                      CharityurlError ? "is-invalid" : ""
                    }`}
                    name="Charityurl"
                    value={data.Charityurl || ""}
                    required=""
                    onChange={handleChnage}
                  />
                  {CharityurlError && (
                    <div className="invalid-feedback">{CharityurlError}</div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Target</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <User />
                    </div>
                  </div>
                  <input
                    type="number"
                    className={`form-control ${
                      TargetError ? "is-invalid" : ""
                    }`}
                    name="Target"
                    value={data.Target || ""}
                    required=""
                    onChange={handleChnage}
                  />
                  {TargetError && (
                    <div className="invalid-feedback">{TargetError}</div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  name="Description"
                  className={`form-control ${
                    DescriptionError ? "is-invalid" : ""
                  }`}
                  rows="3"
                  value={data.Description || ""}
                  onChange={handleChnage}
                ></textarea>
                {DescriptionError && (
                  <div className="invalid-feedback">{DescriptionError}</div>
                )}
              </div>
              <div className="form-group">
                <label>Last Date of Donation </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <User />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="BankName"
                    value={
                      data && data.DonationLastDate
                        ? data.DonationLastDate.split("T")[0]
                        : ""
                    }
                    required=""
                    onChange={handleChnage}
                  />
                </div>
              </div>{" "}
              <div className="form-group">
                <label>Bank Name </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <User />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="BankName"
                    value={data.BankName}
                    required=""
                    onChange={handleChnage}
                  />
                </div>
              </div>{" "}
              <div className="form-group">
                <label>Account Number</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <User />
                    </div>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    name="BankAccountNumber"
                    value={data.BankAccountNumber}
                    required=""
                    onChange={handleChnage}
                  />
                </div>
              </div>{" "}
              <div className="form-group">
                <label>SWIFT Code</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <User />
                    </div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    name="BankIFSC"
                    value={data.BankIFSC}
                    required=""
                    onChange={handleChnage}
                    // disabled="true"
                  />
                </div>
              </div>
              {/* <div className="form-group">
                <label>Date</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <Calendar />
                    </div>
                  </div>
                  <input
                    value={
                      data && data.createdAt ? data.createdAt.split("T")[0] : ""
                    }
                    className="form-control"
                    name="Date"
                    required=""
                    onChange={handleChnage}
                    // disabled="true"
                  />
                </div>
              </div> */}
            </div>
            <div className="form-group row">
              <div className="col-md-12 col-sm-12 back-button">
                <input
                  type="hidden"
                  className="form-control"
                  name="id"
                  defaultValue={266}
                />
                <a href="/Charity">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    Back
                  </button>

                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="btn btn-primary mx-2"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    Save
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCharity;
