import React, { useEffect, useState } from "react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import { toast } from "react-toastify";
import { http } from "../../config/axiosConfig";

const PrivacyandPolicy = () => {
  const [data, setData] = useState({
    Title: "",
    Content: "",
  });

  const Privacyget = async () => {
    try {
      const params = {
        Type: 3,
      };

      const response = await http.get(`GetCms`, {
        params,
      });

      if (response.data.code === 200) {
        setData(response.data?.body);
      }
    } catch (error) {
      console.log(error, ".........not get");
    }
  };

  useEffect(() => {
    Privacyget();
  }, []);

  const Privacyupdatehandle = async (e) => {
    e.preventDefault();
    try {
      const response = await http.put("UpdateCms", data);
      if (response.data.code === 200) {
        setData(response.data?.body);

        toast.success("Privacy policy update Successfully ");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCKEditorChange = (event, editor) => {
    const content = editor.getData();
    setData((prevState) => ({
      ...prevState,
      Content: content || "", // Ensure content is not undefined or null
    }));
  };
  return (
    <>
      <div className="app-content content ">
        <div className="content-overlay" />
        <div className="header-navbar-shadow" />
        <div className="content-wrapper container-xxl p-0">
          <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2">
              <div className="row breadcrumbs-top">
                <div className="col-12">
                  <h2 className="content-header-title float-start mb-0">
                    Privacy Policy
                  </h2>
                </div>
              </div>
            </div>
          </div>
          <div className="content-body">
            <div className="blog-edit-wrapper">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div className="p-2">
                      <div className="d-flex align-items-start">
                        <div className="author-info"></div>
                      </div>{" "}
                      <form onSubmit={Privacyupdatehandle} className="mt-2">
                        <div className="row">
                          <div
                            className="col-md-6 col-12"
                            style={{ width: "100%" }}
                          >
                            <div className="mb-2">
                              <label
                                className="form-label"
                                htmlFor="blog-edit-title"
                              >
                                Title
                              </label>
                              <input
                                disabled="true"
                                name="title"
                                value={data?.Title}
                                id="blog-edit-title"
                                className="form-control"
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="mb-2">
                              <label className="form-label">Content</label>
                              <div id="blog-editor-wrapper">
                                <div id="blog-editor-container">
                                  <div className="editor">
                                    <CKEditor
                                      editor={ClassicEditor}
                                      name="content"
                                      data={data?.Content}
                                      onChange={handleCKEditorChange}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 mb-2"></div>
                          <div className="col-12 mt-50">
                            <button
                              type="submit"
                              className="btn btn-primary me-1"
                            >
                              Save Changes
                            </button>
                          </div>
                        </div>
                      </form>
                      {/*/ Form */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyandPolicy;
