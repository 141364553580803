import { User, Phone, Mail, Codesandbox, Calendar } from "react-feather";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import avatar from "../../config/avatar.png";
import axios from "axios";
import { http, httpfile } from "../../config/axiosConfig";

const ViewUsers = () => {
  const [data, setData] = useState([]);
  const [isEnlarged, setIsEnlarged] = useState(false);
  const [isEnlarged1, setIsEnlarged1] = useState(false);
  const [isEnlarged2, setIsEnlarged2] = useState(false);
  const id = useParams().id;
  const getoneUsers = async () => {
    const params = {
      _id: id,
    };
    await httpfile
      .get(`ViewUser`, { params })
      .then((res) => {
        if (res.data.code === 200) setData(res.data.body);
        console.log(res.data,"res.data.res.data.res.data.res.data.")
      })
      .catch((error) => {
        console.log(error, ".......not get");
      });
  };
  useEffect(() => {
    getoneUsers();
  }, []);
  const getStatusLabel = (status) => {
    return status === 0 ? "Active" : "Inactive";
  };
  console.log(data?.image, "fjkeferkjbkjh");

  const handleMouseEnter = () => {
    setIsEnlarged(true);
  };

  // Function to handle image mouse leave
  const handleMouseLeave = () => {
    setIsEnlarged(false);
  };

  const handleMouseEnter1 = () => {
    setIsEnlarged1(true);
  };

  // Function to handle image mouse leave
  const handleMouseLeave1 = () => {
    setIsEnlarged1(false);
  };
  const handleMouseEnter2 = () => {
    setIsEnlarged2(true);
  };

  // Function to handle image mouse leave
  const handleMouseLeave2 = () => {
    setIsEnlarged2(false);
  };
  // Style object to dynamically adjust image size
  const imageStyle = {
    transform: isEnlarged ? "scale(2.5)" : "scale(1)",
    transition: "transform 0.25s ease",
    cursor: "pointer", // Add cursor pointer on hover
  };
  const imageStyle1 = {
    transform: isEnlarged1 ? "scale(2.5)" : "scale(1)",
    transition: "transform 0.25s ease",
    cursor: "pointer", // Add cursor pointer on hover
  };
  const imageStyle2 = {
    transform: isEnlarged2 ? "scale(2.5)" : "scale(1)",
    transition: "transform 0.25s ease",
    cursor: "pointer", // Add cursor pointer on hover
  };
  return (
    <div className="app-content content">
      <div className="card-header card">
        <h4 className="card-title mb-0">User</h4>
      </div>
      <div className="card-body">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="card card-body">
              <div class="form-group">
                <label>Image</label>
                <div>
                  <img
                    src={
                      data?.image?.[0]
                        ? `${process.env.REACT_APP_BASEAPI_URL}images/Profile/${data.image[0]}`
                        : avatar
                    }
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={imageStyle}
                    alt="no image"
                    className="user-avatar users-avatar-shadow rounded me-2 my-25 cursor-pointer"
                    height={65}
                    width={65}
                    // style={{ border: "double" }}
                  />
                </div>
              </div>

              <div class="form-group row row-cols-md-2">
                <div className="form-group">
                  <label>Name</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <User />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name=":RestaurantOwner"
                      value={data.name}
                      required=""
                      disabled="true"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Email</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <Mail />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name=":email"
                      value={data.email}
                      required=""
                      disabled="true"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Phone</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <Phone />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name=":phonenumber"
                      value={data.phoneNnumberWithCode}
                      required=""
                      disabled="true"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Gender</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <User />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name=":gender"
                      value={data.gender}
                      required=""
                      disabled="true"
                    />
                  </div>
                </div>{" "}
                <div className="form-group">
                  <label>Date Of Birth</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <User />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name=":DOB"
                      value={data && data.DOB ? data.DOB.split("T")[0] : ""}
                      required=""
                      disabled="true"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Preference</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <Codesandbox />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name=":Preference"
                      value={data?.Preference?.map(
                        (item) => item?.preferencesId?.name
                      ).join(", ")}
                      required=""
                     disabled ="true"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Hobbies</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <Codesandbox />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name=":Preference"
                      value={data?.Hobbies?.map(
                        (item) => item?.hobbyId?.name
                      ).join(", ")}
                      required=""
                      disabled="true"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Postal code </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <Codesandbox />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name=":Postalcode"
                      value={data.Postalcode}
                      required=""
                      disabled="true"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Status</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <Codesandbox />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name=":Status"
                      value={getStatusLabel(data.status)}
                      required=""
                      disabled="true"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Bio</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <Codesandbox />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name=":Bio"
                      value={data.Bio}
                      required=""
                      disabled="true"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label> Total Donation Points </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <Codesandbox />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name=":donationPoints"
                      value={data.donationPoints}
                      required=""
                      disabled="true"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Total Donation </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <Codesandbox />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name=":donationPoints"
                      value={data.TotalDonation}
                      required=""
                      disabled="true"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Total Survey Points </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <Codesandbox />
                      </div>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      name=":donationPoints"
                      value={data.TotalPoints}
                      required=""
                      disabled="true"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label>Date</label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <div className="input-group-text">
                        <Calendar />
                      </div>
                    </div>
                    <input
                      value={
                        data && data.createdAt
                          ? data.createdAt.split("T")[0]
                          : ""
                      }
                      className="form-control"
                      name="Date"
                      required=""
                      disabled="true"
                    />
                  </div>
                </div>
                {/* <div>
                  <img
                    src={
                      data?.image?.[1]
                        ? `${process.env.REACT_APP_BASEAPI_URL}images/Profile/${data.image[1]}`
                        : avatar
                    }
                    alt="no image"
                    onMouseEnter={handleMouseEnter1}
                    onMouseLeave={handleMouseLeave1}
                    style={imageStyle1}
                    className="user-avatar users-avatar-shadow rounded me-2 my-25 mx-1 abscursor-pointer"
                    height={45}
                    width={45}
                  />
                  <img
                    src={
                      data?.image?.[2]
                        ? `${process.env.REACT_APP_BASEAPI_URL}images/Profile/${data.image[2]}`
                        : avatar
                    }
                    onMouseEnter={handleMouseEnter2}
                    onMouseLeave={handleMouseLeave2}
                    style={imageStyle2}
                    alt="no image"
                    className="user-avatar users-avatar-shadow rounded me-2 my-25 mx-4 cursor-pointer"
                    height={45}
                    width={45}
                  />
                </div> */}
              </div>
              <div className="form-group row">
                <div className="col-md-12 col-sm-12 back-button">
                  <input
                    type="hidden"
                    className="form-control"
                    name="id"
                    defaultValue={266}
                  />
                  <a href="/User">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      style={{
                        marginTop: "10px",
                      }}
                    >
                      Back
                    </button>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUsers;
