import React, { useEffect, useState } from "react";
import {   User } from "react-feather";

import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import {  httpfile } from "../../config/axiosConfig";

const Profile = () => {
  const [data, setData] = useState({});
  const [previewImage, setPreviewImage] = useState(null);

  const Token = JSON.parse(localStorage.getItem("token"));

  const getprofile = async () => {
    await httpfile
      .get("getProfile", {
        headers: {
          authorization: `Bearer ${Token}`,
        },
      })
      .then((res) => {
        if (res.data.code === 200) {
          setData(res.data.body);
        }
      })
      .catch((error) => {
        console.log(error, ".........not get");
      });
  };

  useEffect(() => {
    getprofile();
  }, []);

  const updateProfile = async (e) => {
    e.preventDefault();

    await httpfile
      .put(`UpdateProfile`, data, {
        headers: {
          authorization: `Bearer ${Token}`,
        },
      })
      .then((res) => {
        if (res.data.success === true) {
          setData(res.data.add_data);
          window.location.reload();
          toast.success("Profile updated successfully");
        }
      })
      .catch((error) => {
        console.log(error, "......not update");
      });
  };

  const handleChnage = (e) => {
    const { name, value } = e.target;
    if (e.target.files && e.target.files[0]) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      setData({ ...data, [name]: e.target.files[0] });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  return (
    <div className="app-content content ">
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <div className="content-wrapper container-xxl p-0">
        <div className="content-header row"></div>
        <div className="content-body">
          <section className="app-user-edit">
            <div className="card">
              <div className="card-body">
                <ul className="nav nav-pills" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link d-flex align-items-center active"
                      id="account-tab"
                      data-bs-toggle="tab"
                      href="#account"
                      aria-controls="account"
                      role="tab"
                      aria-selected="true"
                    >
                      <User />
                      <span className="d-none d-sm-block">Profile</span>
                    </a>
                  </li>
                </ul>
                <div className="tab-content">
                  <div
                    className="tab-pane active"
                    id="account"
                    aria-labelledby="account-tab"
                    role="tabpanel"
                  >
                    <div className="d-flex flex-column mb-2">
                      <img
                        src={
                          previewImage
                            ? previewImage
                            : `${process.env.REACT_APP_BASEAPI_URL}images/Profile/${data?.image?.[0]}`
                        }
                        alt="users avatar"
                        className="user-avatar users-avatar-shadow rounded me-2 my-25 cursor-pointer"
                        height={150}
                        width={150}
                      />
                      <div className="mt-50">
                        <h4>{data.name}</h4>
                      </div>
                    </div>
                    <div className="col-12 d-flex mt-1 px-0">
                      <input
                        onChange={handleChnage}
                        className="form-control mb-1"
                        type="file"
                        id="change-picture"
                        accept="image/png, image/jpeg, image/jpg"
                        name="image"
                      />
                    </div>
                    <form className="form-validate">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-1">
                            <label className="form-label" htmlFor="Name">
                              Name
                            </label>
                            <input
                              onChange={(e) => handleChnage(e)}
                              type="text"
                              className="form-control"
                              value={data.name}
                              name="name"
                              id="Name"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-1">
                            <label className="form-label" htmlFor="email">
                              E-mail
                            </label>
                            <input
                              onChange={(e) => handleChnage(e)}
                              type="email"
                              className="form-control"
                              value={data.email}
                              name="email"
                              id="email"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-1">
                            <label className="form-label" htmlFor="Phone">
                              Phone
                            </label>
                            <input
                              onChange={(e) => handleChnage(e)}
                              type="Phone"
                              className="form-control"
                              value={data.phonenumber}
                              name="phone"
                              id="Phone"
                            />
                          </div>
                        </div>
                        <div className="col-12 d-flex mt-2">
                          <button
                            type="submit"
                            className="btn btn-primary  me-1 me-sm-1"
                            onClick={updateProfile}
                          >
                            Save Changes
                          </button>
                          <Link
                            to="/Dashboard"
                            className="btn btn-outline-secondary"
                          >
                            Back
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Profile;
