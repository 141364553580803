import { User, Mail, Calendar } from "react-feather";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

import { http, httpfile } from "../../config/axiosConfig";

const ViewContest = () => {
  const [data, setData] = useState({});
  const id = useParams().id;
  const navigate = useNavigate();
  const [titleError, setTitleError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [totalUsersError, setTotalUsersError] = useState("");
  const [startTimeError, setStartTimeError] = useState("");
  const [endTimeError, setEndTimeError] = useState("");

  const getOneContest = async () => {
    const params = { _id: id };

    await http
      .get(`GetOnecontest`, { params })
      .then((res) => {
        if (res.data.code === 200) setData(res.data.body);
      })
      .catch((error) => {
        console.log(error, ".......not get");
      });
  };

  useEffect(() => {
    getOneContest();
  }, []);

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
    }));

    if (titleError && name === "Title" && value.trim() !== "") {
      setTitleError("");
    }
    if (descriptionError && name === "Description" && value.trim() !== "") {
      setDescriptionError("");
    }
    if (totalUsersError && name === "TotalUsers" && value.trim() !== "") {
      setTotalUsersError("");
    }
    if (startTimeError && name === "StartTime" && value.trim() !== "") {
      setStartTimeError("");
    }
    if (endTimeError && name === "Endtime" && value.trim() !== "") {
      setEndTimeError("");
    }
  };

  const validateInput = () => {
    let valid = true;
    if (!data.Title) {
      setTitleError("Title is required");
      valid = false;
    }
    if (!data.Description) {
      setDescriptionError("Description is required");
      valid = false;
    }
    if (!data.TotalUsers) {
      setTotalUsersError("TotalUsers is required");
      valid = false;
    }
    if (!data.StartTime) {
      setStartTimeError("StartTime is required");
      valid = false;
    }
    if (!data.Endtime) {
      setEndTimeError("Endtime is required");
      valid = false;
    }
    return valid;
  };

  const updateUser = async (userId) => {
    const contestId = id;
    const status = 1;

    try {
      const result = await Swal.fire({
        title:
          "Are you sure you want to declare this participant as the winner?",
        text: "Declare The Winner",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, sure",
      });

      if (result.isConfirmed) {
        const res = await http.put(`Status_user?contestId=${contestId}`, {
          userId,
          status,
        });

        if (res.data.code === 200) {
          Swal.fire({
            title: "Winner!",
            text: "This participant is the winner.",
            icon: "success",
          });

          getOneContest();
        } else {
          Swal.fire({
            title: "Error!",
            text: "Failed to declare the winner.",
            icon: "error",
          });
        }
      }
    } catch (error) {
      console.error("Error updating user status: ", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateInput()) {
      try {
        const response = await httpfile.put("EditContest", data);
        if (response.data) {
          toast.success("Edit successful");
          navigate("/Contest");
        } else {
          toast.error("Edit failed: No data received from server");
        }
      } catch (error) {
        console.error("Error editing contest:", error);
        toast.error("Failed to edit contest. Please try again later.");
      }
    }
  };

  return (
    <div className="app-content content">
      <div className="card card-header">
        <h4 className="card-title mb-0">Contest View</h4>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="card card-body">
            <div className="row row-cols-md-2">
              <div className="form-group w-100">
                <label>Image</label>
                <div>
                  <img
                    src={`${process.env.REACT_APP_BASEAPI_URL}images/Charity/${data?.files}`}
                    alt="no image"
                    className="user-avatar users-avatar-shadow rounded me-2 my-25 cursor-pointer"
                    height={75}
                    width={75}
                  />
                  <div className="col-12 d-flex mt-1 px-0">
                    <input
                      onChange={handleChange}
                      className="form-control mb-1"
                      type="file"
                      id="change-picture"
                      hidden=""
                      accept="image/png, image/jpeg, image/jpg"
                      name="files"
                    />
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label>Title</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <Mail />
                    </div>
                  </div>
                  <input
                    type="text"
                    className={`form-control ${titleError ? "is-invalid" : ""}`}
                    name="Title"
                    value={data?.Title || ""}
                    required=""
                    onChange={handleChange}
                  />
                  {titleError && (
                    <div className="invalid-feedback">{titleError}</div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="">Description</label>
                <textarea
                  name="Description"
                  id="Description"
                  className={`form-control ${
                    descriptionError ? "is-invalid" : ""
                  }`}
                  rows="3"
                  value={data?.Description || ""}
                  onChange={handleChange}
                ></textarea>
                {descriptionError && (
                  <div className="invalid-feedback">{descriptionError}</div>
                )}
              </div>
              <div className="form-group">
                <label>Total Participants</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <User />
                    </div>
                  </div>
                  <input
                    type="text"
                    className={`form-control ${
                      totalUsersError ? "is-invalid" : ""
                    }`}
                    name="TotalUsers"
                    value={data?.TotalUsers || ""}
                    required=""
                    onChange={handleChange}
                  />
                  {totalUsersError && (
                    <div className="invalid-feedback">{totalUsersError}</div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Start Time</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <User />
                    </div>
                  </div>
                  <input
                    type="text"
                    className={`form-control ${
                      startTimeError ? "is-invalid" : ""
                    }`}
                    disabled
                    name="StartTime"
                    value={data?.StartTime || ""}
                    required=""
                    onChange={handleChange}
                  />{" "}
                  {/* <input
                    type="text"
                    className={`form-control`}
                    name="StartTimePeriod"
                    value={data?.StartTimePeriod || ""}
                    disabled
                    onChange={handleChange}
                  /> */}
                  {startTimeError && (
                    <div className="invalid-feedback">{startTimeError}</div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>End Time</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <User />
                    </div>
                  </div>
                  <input
                    type="text"
                    className={`form-control ${
                      endTimeError ? "is-invalid" : ""
                    }`}
                    name="Endtime"
                    value={data?.Endtime || ""}
                    required=""
                    disabled
                    onChange={handleChange}
                  />
                  {/* <input
                    type="text"
                    className={`form-control`}
                    name="EndTimePeriod"
                    value={data?.EndTimePeriod || ""}
                    onChange={handleChange}
                    disabled
                  /> */}
                  {endTimeError && (
                    <div className="invalid-feedback">{endTimeError}</div>
                  )}
                </div>
              </div>

              <div className="form-group">
                <label>Start Date</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <Calendar />
                    </div>
                  </div>
                  <input
                    value={data?.StartDate ? data.StartDate.split("T")[0] : ""}
                    type="Date"
                    className="form-control"
                    name="StartDate"
                    onChange={handleChange}
                  />
                </div>
              </div>
              {/* <div className="form-group">
                <label>End Date</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <Calendar />
                    </div>
                  </div>
                  <input
                    value={data?.EndDate ? data.EndDate.split("T")[0] : ""}
                    type="Date"
                    className="form-control"
                    name="EndDate"
                    onChange={handleChange}
                  />
                </div>
              </div> */}
            </div>
            <div className="form-group row">
              <div className="col-md-12 col-sm-12 back-button">
                <input
                  type="hidden"
                  className="form-control"
                  name="id"
                  defaultValue={266}
                />
                <a href="/Contest">
                  <button
                    type="button"
                    className="btn btn-secondary mx-2"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    Back
                  </button>
                </a>

                <button
                  type="button"
                  className="btn btn-primary me-1 data-submit waves-effect waves-float waves-light"
                  style={{
                    marginTop: "10px",
                  }}
                  onClick={handleSubmit}
                >
                  Save As
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="content-header row">
          <div className="content-header-left col-md-9 col-12 mb-2"></div>
        </div>
        <div className="content-body">
          <section id="basic-datatable">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div
                    id="DataTables_Table_0_wrapper"
                    className="dataTables_wrapper dt-bootstrap5 no-footer"
                  >
                    <div className="card-header border-bottom p-1">
                      <div className="head-label">
                        <h6 className="mb-0">Contests Participants</h6>
                      </div>
                    </div>

                    <table className="table table-striped table-hover">
                      <thead>
                        <tr>
                          <th scope="col">S.No.</th>
                          <th scope="col">Name</th>
                          <th scope="col">Email</th>
                          <th scope="col">Declare Winner</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.Participants && data.Participants.length === 0 ? (
                          <tr>
                            <td colSpan="6" style={{ textAlign: "center" }}>
                              No data available
                            </td>
                          </tr>
                        ) : (
                          data?.Participants?.map((participant, index) => (
                            <tr key={index}>
                              <th scope="row">{index + 1}</th>
                              <td>{participant?.userId?.name}</td>
                              <td>{participant?.userId?.email}</td>
                              <td>
                                {participant.status === 1 ? (
                                  <button
                                    type="button"
                                    className="btn btn-success"
                                    onClick={() =>
                                      updateUser(participant.userId)
                                    }
                                    disabled={true}
                                  >
                                    Winner
                                  </button>
                                ) : data.Participants.filter(
                                    (p) => p.status === 1
                                  ).length >= 3 ? (
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    disabled={true}
                                    style={{
                                      opacity: 0.5,
                                      cursor: "not-allowed",
                                    }}
                                  >
                                    Make Winner
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={() =>
                                      updateUser(participant.userId)
                                    }
                                  >
                                    Make Winner
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ViewContest;
