import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { http, httpfile } from "../../config/axiosConfig";
import { FaDonate } from "react-icons/fa";

const EditCharity = () => {
  const ID = useParams().id;
  const [data, setData] = useState({ id: ID });
  const navigate = useNavigate();

  const [titleError, settitleError] = useState("");
  const [DescriptionError, setDescriptionError] = useState("");
  const [TargetError, setTargetError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const validateInput = () => {
    let valid = true;
    if (!data.title) {
      settitleError("title is required");
      valid = false;
    }
    if (!data.Description) {
      setDescriptionError("Description is required");
      valid = false;
    }
    if (!data.Target) {
      setTargetError("Target is required");
      valid = false;
    }

    return valid;
  };
  console.log(data, "hjasdgfvkjsdyhe");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const param = {
      // _id:id
    };

    if (validateInput()) {
      await httpfile
        .put("EditCahrity", data)
        .then((res) => {
          console.log(res.data);
          if (res.data) {
            toast.success(" Edit successfully");
            navigate("/Charity");
          }
        })
        .catch((err) => {
          console.log(err, "========== from .Charity");
        });
    }
  };

  const handleChnage = (e) => {
    const { name, value } = e.target;
    if (e.target.files) {
      setData({ ...data, [name]: e.target.files[0] });
    } else {
      setData({ ...data, [name]: value });
    }
  };
  return (
    <>
      <div className="app-content content">
        <div className="card card-header">
          <h4 className="card-title mb-0">Edit Charity</h4>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="card">
              <form
                className="add-new-user modal-content pt-0"
                noValidate="novalidate"
                onSubmit={handleSubmit}
              >
                <div className="modal-body flex-grow-1">
                  <label>Title</label>
                  <div className="mb-1">
                    <input
                      type="text"
                      className={`form-control ${titleError && "is-invalid"}`}
                      id="basic-icon-default-fullname"
                      placeholder="Enter Name"
                      onChange={(e) => handleInputChange(e)}
                      name="title"
                      aria-label="Enter Name"
                      tabIndex={1}
                      aria-describedby="basic-icon-default-fullname2"
                    />
                    {titleError && (
                      <div className="invalid-feedback">{titleError}</div>
                    )}
                  </div>
                </div>
                <div className="modal-body flex-grow-1">
                  <label>Description</label>
                  <div className="mb-1">
                    <input
                      type="text"
                      className={`form-control ${
                        DescriptionError && "is-invalid"
                      }`}
                      id="basic-icon-default-fullname"
                      placeholder="Enter Description"
                      onChange={(e) => handleInputChange(e)}
                      name="Description"
                      aria-label="Enter Description"
                      tabIndex={2}
                      aria-describedby="basic-icon-default-fullname2"
                    />
                    {DescriptionError && (
                      <div className="invalid-feedback">{DescriptionError}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Last Date of Donation </label>
                    <div className="input-group">
                      <div className="input-group-prepend">
                        <div className="input-group-text">
                          <FaDonate />
                        </div>
                      </div>
                      <input
                        type="date"
                        className="form-control"
                        name="date"
                        value={{ [data.DonationLastDate.split("T")[0]]: "" }}
                        required=""
                        onChange={handleChnage}
                      />
                    </div>
                  </div>{" "}
                  <label>Target</label>
                  <div className="mb-1">
                    <input
                      type="text"
                      className={`form-control ${TargetError && "is-invalid"}`}
                      id="basic-icon-default-fullname"
                      placeholder="Enter Description"
                      onChange={(e) => handleInputChange(e)}
                      name="Target"
                      aria-label="Enter Description"
                      tabIndex={2}
                      aria-describedby="basic-icon-default-fullname2"
                    />
                    {TargetError && (
                      <div className="invalid-feedback">{TargetError}</div>
                    )}
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary me-1 data-submit waves-effect waves-float waves-light"
                  >
                    Submit
                  </button>
                  <Link to="/Preferences">
                    <button
                      type="reset"
                      className="btn btn-outline-secondary waves-effect"
                    >
                      Cancel
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Modal to add new user Ends*/}
    </>
  );
};

export default EditCharity;
