import React, { useState } from "react";
import { http } from "../../config/axiosConfig";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export default function AddFaq() {
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const [questionError, setQuestionError] = useState("");
  const [answerError, setAnswerError] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setData({ ...data, [name]: value });
    if (questionError && value.trim() !== "") {
      setQuestionError("");
    }
    if (answerError && value.trim() !== "") {
      setAnswerError("");
    }
  };
  const validateInput = () => {
    let valid = true;
    if (!data.question || data.question.trim() === "") {
      setQuestionError("Question is required");
      valid = false;
    } else {
      setQuestionError("");
    }
    if (!data.answer || data.answer.trim() === "") {
      setAnswerError("Answer is required");
      valid = false;
    } else {
      setAnswerError("");
    }

    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateInput()) {
      await http
        .post(`create_faqs`, data)
        .then((res) => {
          console.log(res.data, "===================================");

          if (res.data) {
            navigate("/Faq");
            toast.success(" Faq add successfully");
          }
        })
        .catch((err) => {
          console.log(err, "========== from .Contest");
        });
    }
  };
  return (
    <div className="app-content content ">
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow"></div>
      <div className="content-wrapper container-xxl p-0">
        <div className="card-header card">
          <h4 className="mb-0 card-title"> Add Faq</h4>
        </div>
        <div className="card">
          <form
            className="add-new-user modal-content pt-0"
            noValidate="novalidate"
            onSubmit={handleSubmit}
          >
            <div className="modal-body flex-grow-1">
              <div className="mb-1">
                <label>Question</label>
                <input
                  type="text"
                  className={`form-control ${questionError && "is-invalid"}`}
                  id="basic-icon-default-fullname"
                  placeholder="Question"
                  onChange={(e) => handleInputChange(e)}
                  name="question"
                  aria-label="John Doe"
                  tabIndex={1}
                  aria-describedby="basic-icon-default-fullname2"
                />
                {questionError && (
                  <div className="invalid-feedback">{questionError}</div>
                )}
              </div>
            </div>
            <div className="modal-body flex-grow-1">
              <div className="mb-1">
                <label>Answer</label>
                <textarea
                  type="text"
                  className={`form-control ${answerError && "is-invalid"}`}
                  id="basic-icon-default-fullname"
                  placeholder="Answer"
                  onChange={(e) => handleInputChange(e)}
                  name="answer"
                  aria-label="John Doe"
                  tabIndex={1}
                  aria-describedby="basic-icon-default-fullname2"
                />
                {answerError && (
                  <div className="invalid-feedback">{answerError}</div>
                )}
              </div>

              <button
                type="submit"
                className="btn btn-primary me-1 data-submit waves-effect waves-float waves-light"
              >
                Submit
              </button>
              <Link to="/Faq">
                <button
                  type="reset"
                  className="btn btn-outline-secondary waves-effect"
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
