import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { http, httpfile } from "../../config/axiosConfig";

const EditBanner = () => {
  const ID = useParams().id;
  const navigate = useNavigate();
  const location = useLocation();

  const files = location?.state.files;

  const [data, setData] = useState({
    id: ID,
    files: files,
  });

  console.log(data, "data");

  const [nameError, setNameError] = useState("");

  //   const handleInputChange = (e) => {
  //     const { name, value } = e.target;
  //     if (e.target.files) {
  //       setData({ ...data, [name]: e.target.files[0] });
  //     } else {
  //       setData({ ...data, [name]: value });
  //     }
  //   };

  //   const validateInput = () => {
  //     let valid = true;
  //     if (!data.name) {
  //       setNameError("Name is required");
  //       valid = false;
  //     }

  //     return valid;
  //   };

  const handleSubmit = async (e) => {
    e.preventDefault();

    console.log(data, "ddhdghdhd");

    await httpfile
      .put("EditBanner", data)
      .then((res) => {
        console.log(res.data);
        if (res.data) {
          toast.success(" Edit successfully");
          navigate("/Banner");
        }
      })
      .catch((err) => {
        console.log(err, "========== from .Banner");
      });
  };

  const handleChnage = (e) => {
    const { name, value } = e.target;
    if (e.target.files) {
      setData({ ...data, [name]: e.target.files[0] });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  return (
    <>
      <div className="app-content content">
        <div className="card card-header">
          <h4 className="card-title mb-0">Edit Banner</h4>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="card">
              <form
                className="add-new-user modal-content pt-0"
                noValidate="novalidate"
                onSubmit={handleSubmit}
              >
                <div className="modal-body flex-grow-1">
                  {/* <label>Name</label>
                  <div className="mb-1">
                    <input
                      type="text"
                      className={`form-control ${nameError && "is-invalid"}`}
                      id="basic-icon-default-fullname"
                      placeholder="Enter Name"
                      onChange={(e) => handleInputChange(e)}
                      name="name"
                      aria-label="Enter Name"
                      tabIndex={1}
                      value={data.name}
                      aria-describedby="basic-icon-default-fullname2"
                    />
                    {nameError && (
                      <div className="invalid-feedback">{nameError}</div>
                    )}
                  </div> */}
                  <label>Image</label>
                  <div>
                    <img
                      src={`${process.env.REACT_APP_BASEAPI_URL}images/Profile/${data?.files}`}
                      alt="no image"
                      className="user-avatar users-avatar-shadow rounded me-2 my-25 cursor-pointer"
                      height={75}
                      width={75}
                      // style={{ border: "double" }}
                    />
                    <div className="col-12 d-flex mt-1 px-0">
                      <input
                        onChange={handleChnage}
                        className="form-control mb-1"
                        type="file"
                        id="change-picture"
                        hidden=""
                        accept="image/png, image/jpeg, image/jpg"
                        name="image"
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="btn btn-primary me-1 data-submit waves-effect waves-float waves-light"
                  >
                    Submit
                  </button>
                  <Link to="/Banner">
                    <button
                      type="reset"
                      className="btn btn-outline-secondary waves-effect"
                    >
                      Cancel
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Modal to add new user Ends*/}
    </>
  );
};

export default EditBanner;
