import React, { useEffect, useState, useRef } from "react";
import { LogOut, User, Lock } from "react-feather";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { http } from "../../config/axiosConfig";

function Header({ openSideBar, sideBarOpenStatus }) {
  const [data, setData] = useState({});
  const bodyRef = useRef();

  const navigate = useNavigate();
  const Token = JSON.parse(localStorage.getItem("token"));

  const profilename = async (e) => {
    await http
      .get(`getProfile`, {
        headers: {
          authorization: `Bearer ${Token}`,
        },
      })
      .then((res) => {
        setData(res.data.body);
      })
      .catch((error) => {
        console.log(error, "......not get");
      });
  };

    useEffect(() => {
    profilename();
  }, []);

  const logout = async (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure",
      text: "You will not be able to recover this  file!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#DD6B55",
      confirmButtonText: "Yes, logout!",
      cancelButtonText: "No, cancel !",
      closeOnConfirm: false,
      closeOnCancel: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        Swal.fire("Logout", "logout", "success");
        try {
          let obj = await http.put(
            `LogoutAdmin`,
            {},
            {
              headers: {
                authorization: `Bearer ${Token}`,
              },
            }
          );

          if (obj.status === 200) {
            localStorage.clear();
            navigate("/");
          }
        } catch (error) {
          console.error(error, "=======logout");
        }
      } else {
        Swal.fire("Cancelled", "Safe", "error");
      }
    });
  };

  return (
    <div>
      <nav className="header-navbar navbar navbar-expand-lg align-items-center floating-nav position-absolute bg_theme navbar-shadow container-xxl">
        <div className="navbar-container d-flex content">
          <div class="bookmark-wrapper d-flex align-items-center">
            <ul class="nav navbar-nav">
              <li class="nav-item">
                <a
                  class="nav-link menu-toggle d-xl-none"
                  onClick={() =>
                    sideBarOpenStatus ? openSideBar(false) : openSideBar(true)
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-menu ficon"
                  >
                    <line x1="3" y1="12" x2="21" y2="12"></line>
                    <line x1="3" y1="6" x2="21" y2="6"></line>
                    <line x1="3" y1="18" x2="21" y2="18"></line>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          <ul className="nav navbar-nav align-items-center ms-auto">
            <li className="nav-item dropdown dropdown-user">
              <a
                className="nav-link dropdown-toggle dropdown-user-link"
                id="dropdown-user"
                href="#"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <div className="user-nav d-sm-flex d-none">
                  <span className="user-name fw-bolder">{data?.name}</span>
                  <span className="user-status">Admin</span>
                </div>
                <span className="avatar">
                  <img
                    className="round"
                    src={`${process.env.REACT_APP_BASEAPI_URL}images/Profile/${data?.image?.[0]}`}
                    alt="avatar"
                    height="40"
                    width="40"
                  />
                  <span className="avatar-status-online"></span>
                </span>
              </a>
              <div
                className="dropdown-menu dropdown-menu-end"
                aria-labelledby="dropdown-user"
              >
                <a className="dropdown-item" href="/Profile">
                  <i className="me-50">
                    <User />
                  </i>{" "}
                  Profile
                </a>
                <a className="dropdown-item px-1" href="/ChangePassword">
                  <i className="me-50">
                    <Lock />
                  </i>{" "}
                  Change Password
                </a>

                <a className="dropdown-item" onClick={(e) => logout(e)}>
                  <i className="me-50">
                    <LogOut />
                  </i>{" "}
                  Logout
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default Header;
