import React, { useState } from "react";
import { Lock, Eye, EyeOff } from "react-feather";
import axios from "axios";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { http } from "../../config/axiosConfig";
import { toast } from "react-toastify";

const Changepassword = () => {
  const [eye, setEye] = useState(false);
  const [eye2, setEye2] = useState(false);
  const [eye3, setEye3] = useState(false);
  const navigate = useNavigate();

  const [data, setData] = useState({
    password: "",
    newpassword: "",
    confirmpassword: "",
  });

  const [oldpasswordError, setOldpasswordError] = useState("");
  const [newpasswordError, setNewpasswordError] = useState("");
  const [confirmpasswordError, setConfirmpasswordError] = useState("");

  const Token = JSON.parse(localStorage.getItem("token"));

const handleInputChange = (e) => {
  const { name, value } = e.target;
  setData((prevData) => ({ ...prevData, [name]: value }));
  if (name === "password" && oldpasswordError && value.trim() !== "") {
    setOldpasswordError("");
  }
  if (name === "newpassword" && newpasswordError && value.trim() !== "") {
    setNewpasswordError("");
  }
  if (
    name === "confirmpassword" &&
    confirmpasswordError &&
    value.trim() !== ""
  ) {
    setConfirmpasswordError("");
  }
};

const validateInput = () => {
  let valid = true;

  if (!data.password) {
    setOldpasswordError("Old Password is required");
    valid = false;
  }
  if (!data.newpassword) {
    setNewpasswordError("New Password is required");
    valid = false;
  }
  if (!data.confirmpassword) {
    setConfirmpasswordError("Confirm Password is required");
    valid = false;
  }
  if (data.newpassword !== data.confirmpassword) {
    setConfirmpasswordError("New Password and Confirm Password do not match");
    valid = false;
  }

  return valid;
};

const handlechange = async (e) => {
  e.preventDefault();
  console.log(Token, "kjefkjnbhj");
  if (validateInput()) {
    try {
      const response = await http.put(`ChangePassword`, data, {
        headers: {
          authorization: `Bearer ${Token}`,
        },
      });

      console.log(response.data);

      if (response.data) {
        toast.success("Password changed successfully");
      }
    } catch (error) {
      console.log(error, "========== from .changepassword");
      if (error.response.status === 401) {
        setOldpasswordError("Old Password is incorrect");
      }
    }
  }
};

  const togglePasswordVisibility = () => {
    setEye(!eye);
  };
  const togglenewPasswordVisibility = () => {
    setEye2(!eye2);
  };
  const toggleconfirmPasswordVisibility = () => {
    setEye3(!eye3);
  };

  return (
    <div className="app-content content ">
      <div className="content-overlay" />
      <div className="header-navbar-shadow" />
      <div className="content-wrapper container-xxl p-0">
        <div className="card-header card">
          <h4 className="card-title mb-0">Change Password</h4>
        </div>
        <div className="content-body">
          <section id="page-account-settings" className="card card-body">
            <div className="row">
              <div className="col-md-3 mb-2 mb-md-0">
                <ul className="nav nav-pills flex-column nav-left"></ul>
              </div>

              <form className="validate-form" onSubmit={handlechange}>
                <div className="row align-items-end gy-1">
                  <div className="col-12 col-sm-6">
                    <div className="">
                      <label
                        className="form-label"
                        htmlFor="account-old-password"
                      >
                        Old Password
                      </label>
                      <div className="input-group form-password-toggle input-group-merge">
                        <input
                          type={eye ? "text" : "password"}
                          onChange={(e) => handleInputChange(e)}
                          className={`form-control ${
                            oldpasswordError && "is-invalid"
                          }`}
                          id="account-old-password"
                          name="password"
                          tabIndex={1}
                          placeholder="Old Password"
                        />{" "}
                        {data.password && (
                          <span
                            className="input-group-text cursor-pointer"
                            onClick={togglePasswordVisibility}
                          >
                            {eye ? <Eye /> : <EyeOff />}
                          </span>
                        )}
                        {oldpasswordError && (
                          <div className="invalid-feedback">
                            {oldpasswordError}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="">
                      <label
                        className="form-label"
                        htmlFor="account-new-password"
                      >
                        New Password
                      </label>
                      <div className="input-group form-password-toggle input-group-merge">
                        <input
                          type={eye2 ? "text" : "password"}
                          onChange={(e) => handleInputChange(e)}
                          id="account-new-password"
                          name="newpassword"
                          tabIndex={2}
                          className={`form-control ${
                            newpasswordError && "is-invalid"
                          }`}
                          placeholder="New Password"
                        />{" "}
                        {data.newpassword && (
                          <span
                            className="input-group-text cursor-pointer"
                            onClick={togglenewPasswordVisibility}
                          >
                            {eye2 ? <Eye /> : <EyeOff />}
                          </span>
                        )}
                        {newpasswordError && (
                          <div className="invalid-feedback">
                            {newpasswordError}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <div className="">
                      <label
                        className="form-label"
                        htmlFor="account-retype-new-password"
                      >
                        Retype New Password
                      </label>
                      <div className="input-group form-password-toggle input-group-merge">
                        <input
                          type={eye3 ? "text" : "password"}
                          onChange={(e) => handleInputChange(e)}
                          className={`form-control ${
                            confirmpasswordError && "is-invalid"
                          }`}
                          id="account-retype-new-password"
                          name="confirmpassword"
                          tabIndex={3}
                          placeholder="New Password"
                        />
                        {data.confirmpassword && (
                          <span
                            className="input-group-text cursor-pointer"
                            onClick={toggleconfirmPasswordVisibility}
                          >
                            {eye3 ? <Eye /> : <EyeOff />}
                          </span>
                        )}
                        {confirmpasswordError && (
                          <div className="invalid-feedback">
                            {confirmpasswordError}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6">
                    <button
                      type="submit"
                      className="btn btn-primary me-1 mt-1"
                      tabIndex={4}
                    >
                      Save Password
                    </button>
                    <Link to="/Dashboard">
                      <button
                        type="reset"
                        className="btn btn-outline-secondary mt-1"
                      >
                        Cancel
                      </button>
                    </Link>
                  </div>
                </div>
              </form>
              {/*/ form */}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Changepassword;
