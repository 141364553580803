import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Edit, Trash2 } from "react-feather";
import { TimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { enableRipple } from "@syncfusion/ej2-base";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { http, httpfile } from "../../config/axiosConfig";

const AddContest = () => {
  const [data, setData] = useState({});
  const navigate = useNavigate();

  const [TitleError, setTitleError] = useState("");
  const [DescriptionError, setDescriptionError] = useState("");
  const [TotalUsersError, setTotalUsersError] = useState("");
  const [StartTimeError, setStartTimeError] = useState("");
  const [StartDateError, setStartDateError] = useState("");
  // const [EndDateError, setEndDateError] = useState("");
  const [EndTimeError, setEndTimeError] = useState("");
  const [StartTimePeriod, setStartTimePeriod] = useState("AM");
  const [EndTimePeriod, setEndTimePeriod] = useState("AM");

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (e.target.files) {
      setData({ ...data, [name]: e.target.files[0] });
    } else {
      setData({ ...data, [name]: value });
    }

    // Reset errors on change
    if (name === "Title" && TitleError && value.trim() !== "") {
      setTitleError("");
    }
    if (name === "Description" && DescriptionError && value.trim() !== "") {
      setDescriptionError("");
    }
    if (name === "TotalUsers" && TotalUsersError && value.trim() !== "") {
      setTotalUsersError("");
    }
    if (name === "StartTime" && StartTimeError && value.trim() !== "") {
      setStartTimeError("");
    }
    if (name === "StartDate" && StartDateError && value.trim() !== "") {
      setStartDateError("");
    }
    // if (name === "EndDate" && EndDateError && value.trim() !== "") {
    //   setEndDateError("");
    // }
    if (name === "EndTime" && EndTimeError && value.trim() !== "") {
      setEndTimeError("");
    }
  };

  const validateInput = () => {
    let valid = true;
    if (!data.Title || data.Title.trim() === "") {
      setTitleError("Title is required");
      valid = false;
    }
    if (!data.Description || data.Description.trim() === "") {
      setDescriptionError("Description is required");
      valid = false;
    }
    if (!data.TotalUsers || data.TotalUsers.trim() === "") {
      setTotalUsersError("Total Users is required");
      valid = false;
    }
    if (!data.StartTime || data.StartTime.trim() === "") {
      setStartTimeError("Start Time is required");
      valid = false;
    }
    if (!data.StartDate || data.StartDate.trim() === "") {
      setStartDateError("Start Date is required");
      valid = false;
    }
    // if (!data.EndDate || data.EndDate.trim() === "") {
    //   setEndDateError("End Date is required");
    //   valid = false;
    // }
    // if (!data.EndTime || data.EndTime.trim() === "") {
    //   setEndTimeError("End Time is required");
    //   valid = false;
    // }

    return valid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateInput()) {
      const obj = {
        ...data,
        StartTimePeriod,
        EndTimePeriod,
      };

      try {
        const response = await httpfile.post(`Contest`, obj);
        if (response.data) {
          navigate("/Contest");
          toast.success("Contest added successfully");
        }
      } catch (error) {
        console.log(error);
        toast.error("Something went wrong");
      }
    }
  };

  return (
    <div className="app-content content">
      <div className="content-overlay"></div>
      <div className="header-navbar-shadow"></div>
      <div className="content-wrapper container-xxl p-0">
        <div className="card-header card">
          <h4 className="mb-0 card-title">Add Contest</h4>
        </div>
        <div className="card">
          <form
            className="add-new-user modal-content pt-0"
            noValidate="novalidate"
            onSubmit={handleSubmit}
          >
            <div className="modal-body flex-grow-1">
              <div className="mb-1">
                <label>Title</label>
                <input
                  type="text"
                  className={`form-control ${TitleError && "is-invalid"}`}
                  placeholder="Title"
                  onChange={handleInputChange}
                  name="Title"
                  value={data.Title || ""}
                  aria-label="Title"
                  tabIndex={1}
                />
                {TitleError && (
                  <div className="invalid-feedback">{TitleError}</div>
                )}
              </div>
              <div className="mb-1">
                <label>Description</label>
                <input
                  type="text"
                  className={`form-control ${DescriptionError && "is-invalid"}`}
                  placeholder="Description"
                  onChange={handleInputChange}
                  name="Description"
                  value={data.Description || ""}
                  aria-label="Description"
                  tabIndex={2}
                />
                {DescriptionError && (
                  <div className="invalid-feedback">{DescriptionError}</div>
                )}
              </div>
              <div className="mb-1">
                <label>Total Participants</label>
                <input
                  type="number"
                  className={`form-control ${TotalUsersError && "is-invalid"}`}
                  placeholder="Total Participants"
                  onChange={handleInputChange}
                  name="TotalUsers"
                  value={data.TotalUsers || ""}
                  aria-label="Total Users"
                  tabIndex={3}
                />
                {TotalUsersError && (
                  <div className="invalid-feedback">{TotalUsersError}</div>
                )}
              </div>
              <div className="mb-1">
                <label>Start Time</label>
                <input
                  type="time"
                  className={`form-control ${StartTimeError && "is-invalid"}`}
                  placeholder="Start Time"
                  onChange={handleInputChange}
                  name="StartTime"
                  value={data.StartTime || ""}
                  aria-label="Start Time"
                  tabIndex={4}
                />
                {/* <select
                  className="form-select"
                  value={StartTimePeriod}
                  onChange={(e) => setStartTimePeriod(e.target.value)}
                  tabIndex={5}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select> */}
                {StartTimeError && (
                  <div className="invalid-feedback">{StartTimeError}</div>
                )}
              </div>
              <div className="mb-1">
                <label>End Time</label>
                <input
                  type="time"
                  className={`form-control ${EndTimeError && "is-invalid"}`}
                  placeholder="End Time"
                  onChange={handleInputChange}
                  name="Endtime"
                  value={data.Endtime || ""}
                  aria-label="End Time"
                  tabIndex={8}
                />
                {/* <select
                  className="form-select"
                  value={EndTimePeriod}
                  onChange={(e) => setEndTimePeriod(e.target.value)}
                  tabIndex={9}
                >
                  <option value="AM">AM</option>
                  <option value="PM">PM</option>
                </select> */}
                {EndTimeError && (
                  <div className="invalid-feedback">{EndTimeError}</div>
                )}
              </div>
              <div className="mb-1">
                <label>Start Date</label>
                <input
                  type="date"
                  className={`form-control ${StartDateError && "is-invalid"}`}
                  placeholder="Start Date"
                  onChange={handleInputChange}
                  name="StartDate"
                  value={data.StartDate || ""}
                  aria-label="Start Date"
                  tabIndex={6}
                />
                {StartDateError && (
                  <div className="invalid-feedback">{StartDateError}</div>
                )}
              </div>
              {/* <div className="mb-1">
                <label>End Date</label>
                <input
                  type="date"
                  className={`form-control ${EndDateError && "is-invalid"}`}
                  placeholder="End Date"
                  onChange={handleInputChange}
                  name="EndDate"
                  value={data.EndDate || ""}
                  aria-label="End Date"
                  tabIndex={7}
                />
                {EndDateError && (
                  <div className="invalid-feedback">{EndDateError}</div>
                )}
              </div> */}

              <div className="mb-1">
                <label>Upload Image</label>
                <input
                  type="file"
                  className="form-control"
                  onChange={handleInputChange}
                  name="image"
                  aria-label="image"
                  tabIndex={10}
                />
              </div>
              <button
                type="submit"
                className="btn btn-primary me-1"
                tabIndex={11}
              >
                Add
              </button>
              <Link to="/Contest">
                <button
                  type="reset"
                  className="btn btn-outline-secondary"
                  tabIndex={12}
                >
                  Cancel
                </button>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddContest;
