import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { http } from "../../config/axiosConfig";

const DonationUsers = () => {
  const [data, setData] = useState([]);
  const { id } = useParams();
  console.log(id, "idddd");
  const getoneDonationUsers = async () => {
    await http
      .get(`get_charity_amount/${id}`)
      .then((res) => {
        console.log(res.data, "res.data");
        if (res.data.status == true) {
          setData(res.data.data);
        }
      })
      .catch((error) => {
        console.log(error, ".......not get");
      });
  };
  useEffect(() => {
    getoneDonationUsers();
  }, []);

  return (
    <div>
      <div className="app-content content ">
        <div className="content-overlay"></div>
        <div className="header-navbar-shadow"></div>
        <div className="content-wrapper container-xxl p-0">
          <div className="content-header row">
            <div className="content-header-left col-md-9 col-12 mb-2"></div>
          </div>
          <div className="content-body">
            <section id="basic-datatable">
              <div className="row">
                <div className="col-12">
                  <div className="card">
                    <div
                      id="DataTables_Table_0_wrapper"
                      className="dataTables_wrapper dt-bootstrap5 no-footer"
                    >
                      <div className="card-header border-bottom p-1">
                        <div className="head-label">
                          <h6 className="mb-0">Charity Donation</h6>
                        </div>
                        <div className="dt-action-buttons text-end">
                          {/* <div className="dt-buttons d-inline-flex">
                            <Link to="/CharityCreate">
                              <button
                                type="submit"
                                className="btn btn-primary me-1 data-submit waves-effect waves-float waves-light"
                              >
                                Add Charity
                              </button>
                            </Link>
                          </div> */}
                        </div>
                      </div>

                      {/* <div className="pagination-container">
                        <div className="pagination-select">
                          <label for="">Show</label>
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => setEntriesPerPage(e.target.value)}
                            value={entriesPerPage}
                          >
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="35">35</option>
                            <option value="50">50</option>
                          </select>
                          <label for="">Entry</label>
                        </div>

                        <div className="pagination-search">
                          <label for="search">
                            Search:
                            <input
                              type="search"
                              value={searchItem}
                              onChange={handleInputChange}
                            />
                          </label>
                        </div>
                      </div> */}
                      <table className="table table-striped table-hover">
                        <thead>
                          <tr>
                            <th scope="col">S.No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone Number</th>
                            <th scope="col">Charity_amount</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data?.length === 0 ? (
                            <tr>
                              <td
                                style={{
                                  textAlign: "center",
                                }}
                                colSpan="6"
                              >
                                No data available
                              </td>
                            </tr>
                          ) : (
                            data.map((one, index) => (
                              <tr key={index}>
                                <th scope="row">{index + 1}</th>
                                <td>{one?.name}</td>
                                <td>{one?.email}</td>
                                <td>{one?.phoneNnumberWithCode}</td>
                                <td>{one?.Charity_amount}</td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DonationUsers;
