import { User, Phone, Mail, Codesandbox, Calendar } from "react-feather";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { http, httpfile } from "../../config/axiosConfig";
import { toast } from "react-toastify";

const ContactUsView = () => {
  const [data, setData] = useState([]);
  const id = useParams().id;

  const [NameError, setNameError] = useState("");
  const [emailError, setemailError] = useState("");
  const [DescriptionError, setDescriptionError] = useState("");
  const [phone_NumberError, setphone_NumberError] = useState("");
  const navigate = useNavigate();

  const getoneUsers = async () => {
    const params = {
      _id: id,
    };
    await http
      .get(`getoneContact`, { params })
      .then((res) => {
        if (res.data.code === 200) setData(res.data.body);
      })
      .catch((error) => {
        console.log(error, ".......not get");
      });
  };
  useEffect(() => {
    getoneUsers();
  }, []);

  const handleChnage = (e) => {
    const { name, value } = e.target;
    if (e.target.files) {
      setData({ ...data, [name]: e.target.files[0] });
    } else {
      setData({ ...data, [name]: value });
    }
    if (NameError && value.trim() !== "") {
      setNameError("");
    }
    if (DescriptionError && value.trim() !== "") {
      setDescriptionError("");
    }
    if (emailError && value.trim() !== "") {
      setemailError("");
    }   if (phone_NumberError && value.trim() !== "") {
      setphone_NumberError("");
    }
  };

  const validateInput = () => {
    let valid = true;
    if (!data.Name) {
      setNameError("Name is required");
      valid = false;
    }
    if (!data.Descrption) {
      setDescriptionError("Description is required");
      valid = false;
    }
    if (!data.email) {
      setemailError("email is required");
      valid = false;
    }    if (!data.phone_Number) {
      setphone_NumberError("phone_NumberError is required");
      valid = false;
    }

    return valid;
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateInput()) {
      await httpfile
        .put("update_Contact", data)
        .then((res) => {
          console.log(res.data);
          if (res.data) {
            toast.success(" Edit successfully");
            navigate(-1);
          }
        })

        .catch((err) => {
          console.log(err, "========== from .Charity");
        });
    }
  };
  return (
    <div className="app-content content">
      <div className="card card-header">
        <h4 className="card-title mb-0">Contact Us View</h4>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="card card-body">
            <div className="row row-cols-md-2">
              <div className="form-group">
                <label>Name</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <Mail />
                    </div>
                  </div>
                  <input
                    type="text"
                    className={`form-control ${NameError ? "is-invalid" : ""}`}
                    name="Name"
                    value={data.Name || ""}
                    required=""
                    onChange={handleChnage}
                  />
                  {NameError && (
                    <div className="invalid-feedback">{NameError}</div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Email</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <User />
                    </div>
                  </div>
                  <input
                    type="Email"
                    className={`form-control ${emailError ? "is-invalid" : ""}`}
                    name="email"
                    value={data.email || ""}
                    required=""
                    onChange={handleChnage}
                  />
                  {emailError && (
                    <div className="invalid-feedback">{emailError}</div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Phone Number</label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <User />
                    </div>
                  </div>
                  <input
                    type="Number"
                    className={`form-control ${
                      phone_NumberError ? "is-invalid" : ""
                    }`}
                    name="phone_Number"
                    value={data.phone_Number || ""}
                    required=""
                    onChange={handleChnage}
                  />
                  {phone_NumberError && (
                    <div className="invalid-feedback">{phone_NumberError}</div>
                  )}
                </div>
              </div>
              <div className="form-group">
                <label>Description</label>
                <textarea
                  name="Descrption"
                  className={`form-control ${
                    DescriptionError ? "is-invalid" : ""
                  }`}
                  rows="3"
                  value={data.Descrption || ""}
                  onChange={handleChnage}
                ></textarea>
                {DescriptionError && (
                  <div className="invalid-feedback">{DescriptionError}</div>
                )}
              </div>
            </div>
            <div className="form-group row">
              <div className="col-md-12 col-sm-12 back-button">
                <input
                  type="hidden"
                  className="form-control"
                  name="id"
                  defaultValue={266}
                />
                <a href="/getConatct">
                  <button
                    type="button"
                    className="btn btn-secondary"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    Back
                  </button>

                  <button
                    onClick={handleSubmit}
                    type="submit"
                    className="btn btn-primary mx-2"
                    style={{
                      marginTop: "10px",
                    }}
                  >
                    Save
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUsView;
