import axios from "axios";
const URL_endpoit = process.env.REACT_APP_BASEAPI_URL;
console.log(URL_endpoit, "URL_endpoitpp");
export const http = axios.create({
  baseURL: URL_endpoit,
  headers: {
    // "Content-Type": "application/json,multipart/form-data",
  },
});
export const httpfile = axios.create({
  baseURL: URL_endpoit,
  headers: {
    "Content-Type": "application/json,multipart/form-data",
  },
});
