import React, { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";
// import axios from "asiox"

function Adminlayout() {
  const [open, setOpen] = useState(true);
  return (
    <div
      className="main-wrapper d-flex justify-content-start flex-column"
      style={{ minHeight: "100vh" }}
    >
      <Header openSideBar={setOpen} sideBarOpenStatus={open} />
      <Sidebar open={open} setOpen={setOpen} />
      <div className="main-content">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

export default Adminlayout;
